import { type InterviewAnswer, type InterviewQuestionCategory } from '@gqlTypes'
import { indexPropertyById } from '#helpers/array.helper'
import { QuestionCard } from './QuestionCard'
import { type ReactElement } from 'react'
import { InterviewSection } from '../InterviewSection'
import { useTranslation } from 'react-i18next'
import { Stack } from '@mui/material'

interface Props {
	interviewQuestionCategories: InterviewQuestionCategory[]
	interviewAnswers: InterviewAnswer[]
	isEditable: boolean
}

export const InterviewQuestions = ({
	interviewQuestionCategories,
	interviewAnswers,
	isEditable
}: Props): ReactElement | null => {
	const answers = indexPropertyById(interviewAnswers, 'answer')
	const { t } = useTranslation()
	const categories = interviewQuestionCategories.map((category) => {
		const questionsWithAnswer = category.questions.map(({ questionId: id, label, type, responses }) => {
			return { id, label, answer: answers[id], type, responses }
		})
		return { id: category.id, label: category.label, questions: questionsWithAnswer }
	})

	if (categories.length === 0) return null

	const questionCount = categories.map((category) => category.questions).flat().length
	const filledQuestionCount = Object.values(answers).filter((answer) => answer !== '').length
	const progression = (filledQuestionCount / questionCount) * 100
	return (
		<InterviewSection
			title={t('interview:questions.section')}
			isLabelGreen
			progression={progression}
			hasAssessorHeadings
		>
			<Stack spacing={4}>
				{categories.map(({ id, label, questions }) => (
					<QuestionCard key={id} title={label} questions={questions} isEditable={isEditable} />
				))}
			</Stack>
		</InterviewSection>
	)
}
