import { InfoLayoutLabel } from '@ui/core/InfoLayoutLabel'
import { Box, IconButton, Stack, type StackProps, TableRow } from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import Tooltip from '@mui/material/Tooltip'
import { InfoLayoutValue } from '@ui/core/InfoLayoutValue'
import { type ReactNode } from 'react'
import { useCustomTranslation } from '#translations/useCustomTranslation'

interface Props extends StackProps {
	title?: string
	tooltip?: string
	children?: ReactNode
	translationKey?: string
	required?: boolean
}

export default function TableLayoutRow({
	title = '',
	children,
	tooltip = '',
	translationKey = '',
	required = false,
	...stackProps
}: Props): JSX.Element {
	const { t, i18n } = useCustomTranslation()

	const tooltipTranslationKey = `${translationKey}.tooltip`
	const tooltipLabel = translationKey !== '' && i18n.exists(tooltipTranslationKey) ? t(tooltipTranslationKey) : tooltip

	return (
		<TableRow>
			<InfoLayoutLabel verticalAlign="center" required={required}>
				{translationKey === '' ? title : t(`${translationKey}.title`)}
			</InfoLayoutLabel>
			<InfoLayoutValue>
				<Stack direction="row" spacing={1} sx={{ alignItems: 'center' }} {...stackProps}>
					{children}
					{tooltipLabel !== '' ? (
						<Tooltip
							sx={{
								marginLeft: '2px',
								marginTop: '-4px'
							}}
							title={tooltipLabel}
						>
							<IconButton>
								<InfoOutlinedIcon />
							</IconButton>
						</Tooltip>
					) : (
						<Box sx={{ width: 46 }} />
					)}
				</Stack>
			</InfoLayoutValue>
		</TableRow>
	)
}
