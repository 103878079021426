import Typography from '@mui/material/Typography'
import { LocalDate } from '@ui/core/LocalDate'
import { type ReactElement } from 'react'

interface Props {
	date: Date
}

export const ConversationTimestamp = ({ date }: Props): ReactElement => {
	return (
		<Typography component="div" variant="body2" sx={{ textAlign: 'center', color: 'grays.gray2' }}>
			<LocalDate date={date} dateStyle="full" />
			{' • '}
			<LocalDate date={date} dateStyle="none" timeStyle="short" />
		</Typography>
	)
}
