import { useRef, useEffect, type CSSProperties, type ReactNode, type ReactElement } from 'react'

interface Props {
	style: CSSProperties
	children: ReactNode
}

export const Conversation = ({ style, children }: Props): ReactElement => {
	const scrollContentRef = useRef<HTMLDivElement>(null)

	useEffect(() => {
		if (scrollContentRef.current != null) {
			const el = scrollContentRef.current
			el.scrollTop = el.scrollHeight
		}
	}, [children, scrollContentRef])

	return (
		<div
			ref={scrollContentRef}
			style={{
				overflowY: 'auto',
				overflowX: 'hidden',
				...style
			}}
		>
			{children}
		</div>
	)
}
