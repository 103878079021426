import { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { Typography } from '@mui/material'
import { CampaignSelector } from '@domains/campaigns/CampaignSelector'
import { FeedbackRequestTemplateSelector } from './FeedbackRequestTemplateSelector'

export const TemplateFeedbackRequestForm = (): ReactElement => {
	const { t } = useTranslation()

	return (
		<>
			<CampaignSelector />
			<FeedbackRequestTemplateSelector />
			<Typography>{t('modal:massFeedback.mode.template.text')}</Typography>
		</>
	)
}
