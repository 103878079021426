import { ScreenId } from '@gqlTypes'
import { Redirect } from 'react-router-dom'
import { ArticleList } from './list/ArticlesList.page'

enum Path {
	default = '/article',
	list = '/article/list'
}

const routes = [
	{
		path: Path.list,
		children: <ArticleList />
	},
	{
		path: Path.default,
		children: <Redirect to={Path.list} />
	}
]

export const ArticlesRouter = {
	roleId: ScreenId.Articles,
	paths: Path,
	routes
}
