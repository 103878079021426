import { Box, Typography } from '@mui/material'
import InputTextField from '@ui/form/inputTextField'
import { type ReactElement } from 'react'
import { useFormContext } from 'react-hook-form'

interface Props {
	id: string
	isEditable: boolean
	answer: string
}

export const InterviewTextQuestion = ({ id, isEditable, answer }: Props): ReactElement => {
	const { control } = useFormContext()
	return (
		<Box ml={5}>
			{isEditable ? (
				<InputTextField control={control} name={`answers[${id}]`} multiline defaultValue={answer} />
			) : (
				<Typography color="primary">{answer}</Typography>
			)}
		</Box>
	)
}
