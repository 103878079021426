import { LocalLoader } from '@shared/components/layout/LocalLoader'
import { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { DiscussionMessageContent } from './DiscussionMessageContent'
import { ConversationTimestamp } from './conversation/ConversationTimestamp'
import { ConversationMessageContainer } from './conversation/ConversationMessageContainer'
import { type DecryptedMessage } from './useDiscussionContent'

interface Props {
	messages: DecryptedMessage[]
	isLoading: boolean
	displayName: string
}

export const DiscussionMessages = ({ isLoading, messages, displayName }: Props): ReactElement => {
	const { t } = useTranslation()
	if (isLoading) return <LocalLoader />
	if (messages.length === 0) return <div key={1}>{t('discussionDetailPage.noMessages', { displayName })}</div>

	return (
		<>
			{messages.map((message) => (
				<div key={message.id}>
					{message.hasTimeStamp && <ConversationTimestamp date={message.createdAt} />}
					<ConversationMessageContainer
						date={message.createdAt}
						variant={message.isSelf ? 'self' : 'other'}
						author={message.author}
					>
						<DiscussionMessageContent {...message} />
					</ConversationMessageContainer>
				</div>
			))}
		</>
	)
}
