import { type ReactElement, useEffect } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import {
	type GetInterviewDetailsQuery,
	useCreateMotivationTestMutation,
	useGetMotivationTestByInterviewQuery
} from '@gqlTypes'
import { LocalLoader } from '@shared/components/layout/LocalLoader'
import { useTranslation } from 'react-i18next'
import { MotivationQuestions } from './question/MotivationQuestions'
import { ExitFormWarning } from '@ui/form/ExitFormWarning'
import { useInterviewState } from '@domains/interviews/details/useInterviewState'
import { InterviewSection } from '@domains/interviews/details/InterviewSection'
import { MotivationVideoCard } from './MotivationVideoCard'
import { SaveMotivationButton } from './SaveMotivationButton'
import { indexPropertyById } from '#helpers/array.helper'
import { Typography } from '@mui/material'
import { MandatoryStepAlert } from '@domains/interviews/details/interviewEvaluation/MandatoryStepAlert'

interface Props {
	interview: GetInterviewDetailsQuery['getInterview']
	hasAllMotivationQuestionsRequired: boolean
}

export interface MotivationFormInputs {
	labels: Record<string, string[]>
	answers: Record<string, string>
}

export const MotivationForm = ({ interview, hasAllMotivationQuestionsRequired }: Props): ReactElement | null => {
	const interviewId = interview.id ?? ''
	const { isMotivationEditable, isMissingMotivationStep } = useInterviewState(interview)
	const [createMotivationTest] = useCreateMotivationTestMutation()
	const {
		data,
		loading: isLoading,
		refetch
	} = useGetMotivationTestByInterviewQuery({
		variables: { interviewId },
		fetchPolicy: 'cache-and-network'
	})

	const motivationTestId = data?.getMotivationTestByInterview?.id ?? ''
	const motivationTest = data?.getMotivationTestByInterview
	const formMethods = useForm<MotivationFormInputs>({
		mode: 'onChange',
		defaultValues: { labels: {}, answers: {} }
	})
	const { formState, reset } = formMethods
	const { t } = useTranslation()
	const isSaveButtonDisplayed = motivationTestId === '' || !interview?.isArchived
	const hasMotivationStep = interview.stepsState.motivation != null

	useEffect(() => {
		if (motivationTest == null) return

		const answers = indexPropertyById(motivationTest.answersToQuestionsArray, 'answer')
		const labels = indexPropertyById(motivationTest.answersToLabelsArray, 'labels')
		reset({ labels, answers })
	}, [reset, motivationTest])

	useEffect(() => {
		const initTest = async (): Promise<void> => {
			const motivationTestInput = { interviewId, motivationsLabels: [], motivationsQuestions: [] }
			const userIntegrationId = interview.userIntegration.id
			const trackType = interview.integrationType ?? ''
			const variables = { userIntegrationId, trackType, motivationTestId, motivationTestInput }
			await createMotivationTest({ variables })
			await refetch()
		}

		if (data != null && motivationTestId === '' && isMotivationEditable && hasMotivationStep) {
			// eslint-disable-next-line @typescript-eslint/no-floating-promises
			initTest()
		}
	}, [data, motivationTestId])

	if (isLoading) return <LocalLoader />
	if (data?.getMotivationTestByInterview === undefined) return null
	if (!hasMotivationStep && motivationTestId === '') return null

	const { motivationQuestionGroups } = data.getMotivationTestByInterview
	const questions = motivationQuestionGroups.map((group) => group.questions).flat()
	const questionsCount = questions.length
	const questionsWithAnswersCount = questions.filter(
		(question) => question.answer !== '' || question.labels.some(({ isSelected }) => isSelected)
	).length
	const progression = (100 * questionsWithAnswersCount) / questionsCount

	const videoQuestions = questions.filter((question) => question.isAnswerVideo)

	const isMotivationTestCompleted = !hasAllMotivationQuestionsRequired || progression === 100 || !isSaveButtonDisplayed

	return (
		<InterviewSection
			title={t('interview:motivations.section')}
			progression={progression}
			right={
				<>
					<MandatoryStepAlert isMissing={isMissingMotivationStep} />
					{isMotivationTestCompleted || !isMotivationEditable ? null : (
						<Typography sx={{ color: 'warning.main', fontWeight: 'bold' }}>
							{t('interview:motivations.allQuestionsRequired')}
						</Typography>
					)}
				</>
			}
			hasAssessedHeadings
		>
			<FormProvider {...formMethods}>
				<ExitFormWarning isModified={formState.isDirty} />
				<form style={{ display: 'flex', flexDirection: 'column', gap: 30 }}>
					{motivationQuestionGroups.map(({ id, label, questions }) => (
						<MotivationQuestions key={id} questions={questions} isEditable={isMotivationEditable} title={label} />
					))}
					<MotivationVideoCard questions={videoQuestions} direction="row" />
					{isSaveButtonDisplayed ? <SaveMotivationButton {...{ interview, motivationTestId }} /> : null}
				</form>
			</FormProvider>
		</InterviewSection>
	)
}
