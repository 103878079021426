import AutoCompleteFilter from '@features/lists/components/Filters/AutoCompleteFilter'
import { useGetCampaignsQuery } from '@gqlTypes'
import { filterOperation } from '@ui/filter/FilterForm'
import { InputFilter } from '@ui/filter/InputFilter'
import { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

export const CampaignFilter = (): ReactElement => {
	const { t } = useTranslation()
	const { data } = useGetCampaignsQuery({ fetchPolicy: 'cache-and-network' })
	const items = data?.getCampaigns.map(({ id, label }) => ({ value: id, label })) ?? []
	return (
		<InputFilter keyName="campaignId" operation={filterOperation.EQUAL}>
			<AutoCompleteFilter
				options={items}
				label={t('lists:filter.campaign.label')}
				allValueLabel={t('lists:filter.campaign.allLabel')}
				hasNoneValue={false}
				width="100%"
			/>
		</InputFilter>
	)
}
