import { type ReactElement, type ReactNode } from 'react'
import { Stack, Typography, ButtonBase, useTheme } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { useToggle } from '@shared/hooks/useToggle'
import { Box } from '@mui/system'
import { ProgressionChip } from '@ui/progress/ProgressionChip'
import { useTranslation } from 'react-i18next'

interface Props {
	progression?: number
	progressionChip?: ReactElement | null
	title: string
	isDisplay?: boolean
	children: ReactNode
	isLabelGreen?: boolean
	right?: ReactNode
	hasAssessedHeadings?: boolean
	hasAssessorHeadings?: boolean
}

export const InterviewSection = ({
	title,
	progression,
	progressionChip,
	hasAssessedHeadings = false,
	hasAssessorHeadings = false,
	children,
	isDisplay = true,
	isLabelGreen = false,
	right
}: Props): ReactElement | null => {
	const { t } = useTranslation('interview')
	const theme = useTheme()
	const [isOpen, toggleOpen] = useToggle(true)
	const iconSize = { width: 28, height: 28 }

	if (!isDisplay) return null

	return (
		<>
			<Stack spacing={1} direction="row" alignItems="center" justifyContent="space-between">
				<ButtonBase onClick={toggleOpen} disableRipple sx={{ alignSelf: 'start' }}>
					<Stack spacing={1} direction="row" alignItems="center">
						{isOpen ? (
							<KeyboardArrowDownIcon className="no-print" sx={iconSize} />
						) : (
							<ChevronRightIcon className="no-print" sx={iconSize} />
						)}
						<Typography sx={{ fontSize: '24px', color: 'primary.main', fontWeight: 'bold' }}>{title}</Typography>
						{hasAssessorHeadings ? (
							<Typography
								sx={{
									backgroundColor: `${theme.palette.lightBlue.light}70`,
									paddingY: 0.1,
									paddingX: 1,
									borderRadius: 3,
									color: theme.palette.primary.contrastText
								}}
							>
								{t('interviewSectionTitles.assessor')}
							</Typography>
						) : null}
						{hasAssessedHeadings ? (
							<Typography
								sx={{
									backgroundColor: `${theme.palette.lightBlue.light}20`,
									paddingY: 0.1,
									paddingX: 1,
									borderRadius: 3,
									color: theme.palette.lightBlue.light
								}}
							>
								{t('interviewSectionTitles.assessed')}
							</Typography>
						) : null}
						{progressionChip ?? <ProgressionChip progression={progression} isLabelGreen={isLabelGreen} />}
					</Stack>
				</ButtonBase>
				{right}
			</Stack>
			<Box sx={{ display: isOpen ? 'block' : 'none' }}>{children}</Box>
		</>
	)
}
