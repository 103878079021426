import TableLayoutRow from '@shared/components/cards/TableLayoutRow'
import InputTextField from '@ui/form/inputTextField'
import CardLayout from '@ui/core/CardLayout'
import { TableLayout } from '@ui/core/TableLayout'
import { type ReactElement } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { AuthorizedOrganizationsAutocomplete } from '../AuthorizedOrganizationsAutocomplete'
import { OfferTypeAutocomplete } from './OfferTypeAutocomplete'
import { UserFunctionSelect } from './UserFunctionSelect'
import { ContractDurationSelect } from './ContractDurationSelect'
import { WorkArrangementsSelect } from './WorkArrangementsSelect'
import { StartMonthsSelect } from './StartMonthsSelect.tsx'
import { EducationLevelSelector } from './EducationLevelSelector'
import { GeoLocationSelect } from './GeoLocationSelect'

export const OfferMainCard = (): ReactElement | null => {
	const { t } = useTranslation()
	const { control, errors } = useFormContext()

	return (
		<CardLayout noDivider title={t('offers:details.main.title')} tooltip={t('offers:details.main.tooltip')}>
			<TableLayout>
				<AuthorizedOrganizationsAutocomplete />
				<OfferTypeAutocomplete />
				<TableLayoutRow translationKey="offers:details.main.label">
					<InputTextField control={control} name="label" error={errors.label} required />
				</TableLayoutRow>
				<UserFunctionSelect />

				<EducationLevelSelector />
				<WorkArrangementsSelect />
				<GeoLocationSelect />
				<ContractDurationSelect />
				<StartMonthsSelect />
			</TableLayout>
		</CardLayout>
	)
}
