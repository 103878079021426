import useUserBContext from '@shared/hooks/useUserBContext'
import { type InterviewStepsState, type GetInterviewDetailsQuery } from '@gqlTypes'
import { useRole } from '@shared/hooks/useRole'

interface InterviewState {
	isReadOnly: boolean
	isEditable: boolean
	isAssessorSigned: boolean
	isAssessedSigned: boolean
	hasStartedSigning: boolean
	isMotivationEditable: boolean
	isAssesseePreparation: boolean
	isInterviewAssessee: boolean
	hasSkillEvaluation: boolean
	isMissingMandatorySteps: boolean
	isMissingSoftSkillsStep: boolean
	isMissingProfileStep: boolean
	isMissingMotivationStep: boolean
	isMissingBrandFeedbackStep: boolean
}

export const useInterviewState = (interview: GetInterviewDetailsQuery['getInterview']): InterviewState => {
	const userB = useUserBContext()
	const { screens } = useRole()
	const mandatoryStepIds = interview.template?.mandatoryStepIds ?? []

	const isMissingStep = (stepId: string): boolean =>
		mandatoryStepIds.includes(stepId) && interview.stepsState[stepId as keyof InterviewStepsState] !== true
	const isMissingMandatorySteps = mandatoryStepIds.some(
		(stepId) => interview.stepsState[stepId as keyof InterviewStepsState] !== true
	)
	const isMissingSoftSkillsStep = isMissingStep('softskills')
	const isMissingProfileStep = isMissingStep('profile')
	const isMissingMotivationStep = isMissingStep('motivation')
	const isMissingBrandFeedbackStep = isMissingStep('brandFeedbackResponse')

	const isAssessorSigned = interview.signatures.assessor.isSigned
	const isAssessedSigned = interview.signatures.assessed.isSigned
	const hasStartedSigning = isAssessorSigned || isAssessedSigned
	const isReadOnly =
		userB.id === interview.userId || hasStartedSigning || interview.isArchived || isMissingMandatorySteps
	const isEditable = !isReadOnly
	const isInterviewAssessee = userB.id === interview.userId
	const isMotivationEditable =
		(userB.id === interview.userId || screens.interviewGrid.hasAdminAccess) &&
		!hasStartedSigning &&
		!interview.isArchived

	const isAssesseePreparation = userB.id === interview.userId && interview.isDraft
	const hasSkillEvaluation =
		(isAssesseePreparation && interview.stepsState.softskills != null) || !isAssesseePreparation

	return {
		isReadOnly,
		isAssessorSigned,
		isAssessedSigned,
		hasStartedSigning,
		isEditable,
		isMotivationEditable,
		isAssesseePreparation,
		isInterviewAssessee,
		hasSkillEvaluation,
		isMissingMandatorySteps,
		isMissingSoftSkillsStep,
		isMissingProfileStep,
		isMissingMotivationStep,
		isMissingBrandFeedbackStep
	}
}
