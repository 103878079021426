import AutoCompleteFilter from '@features/lists/components/Filters/AutoCompleteFilter'
import { useGetNationalitiesQuery } from '@gqlTypes'
import { filterOperation } from '@ui/filter/FilterForm'
import { InputFilter } from '@ui/filter/InputFilter'
import { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

export const NationalitiesFilter = (): ReactElement | null => {
	const { t } = useTranslation()

	const { data, loading: isLoading } = useGetNationalitiesQuery()

	if (isLoading || data?.getNationalities == null) return null
	const educationLevels = data.getNationalities.map(({ id: value, label }) => ({ value, label }))

	return (
		<InputFilter keyName="userRef.nationality" operation={filterOperation.EQUAL}>
			<AutoCompleteFilter
				options={educationLevels}
				label={t('offers:resumeLibrary.filters.nationality.label')}
				width="100%"
			/>
		</InputFilter>
	)
}
