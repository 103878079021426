import { myProfileRouter } from '@domains/myProfile/myProfile.router'
import { Divider, ListItem, ListItemButton, Typography } from '@mui/material'
import { type ReactElement } from 'react'
import { Link } from 'react-router-dom'
import { OrganizationSwitcher } from './OrganizationSwitcher'
import { ROUTE_LOGOUT } from '../../router/routes'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import { type OrganizationListItem } from '@gqlTypes'

interface Props {
	organizationList: OrganizationListItem[]
	currentOrganizationId: string
	handleClose: () => void
}

export const UserActions = ({ organizationList, currentOrganizationId, handleClose }: Props): ReactElement => {
	const { t } = useCustomTranslation()
	const hasMultipleOrganizations = organizationList.length > 1

	return (
		<>
			<ListItem disablePadding>
				<ListItemButton component={Link} to={myProfileRouter.path.account}>
					<Typography color="primary">{t('menu.user.myAccount')}</Typography>
				</ListItemButton>
			</ListItem>
			<Divider />
			<ListItem disablePadding>
				{hasMultipleOrganizations ? (
					<OrganizationSwitcher
						organizationList={organizationList}
						currentOrganizationId={currentOrganizationId}
						handleClose={handleClose}
					/>
				) : null}
			</ListItem>
			<Divider />
			<ListItem disablePadding>
				<ListItemButton component={Link} to={ROUTE_LOGOUT}>
					<Typography sx={{ fontWeight: 'bold' }} color="error">
						{t('login.disconnect')}
					</Typography>
				</ListItemButton>
			</ListItem>
		</>
	)
}
