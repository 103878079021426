import TableLayoutRow from '@shared/components/cards/TableLayoutRow'
import InputTextField from '@ui/form/inputTextField'
import { type ReactElement } from 'react'

export const PublicTitleField = (): ReactElement => {
	const name = 'publicTitle'
	return (
		<TableLayoutRow translationKey={`myAccount:generalInformations.fields.${name}`}>
			<InputTextField name={name} />
		</TableLayoutRow>
	)
}
