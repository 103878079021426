import { type ReactElement } from 'react'
import { Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { LocalLoader } from '@shared/components/layout/LocalLoader'
import { type IntegrationType, useGetUserIntegrationSoftSkillRestitutionQuery } from '@gqlTypes'
import { useSnackbar } from 'notistack'
import { Severities } from '@application/snackbar/Severities'
import { SoftSkillRestitutionSection } from './SoftSkillRestitutionSection'
import { EmptyList } from '@ui/list/EmptyList'

interface Props {
	userIntegrationId: string
	userIntegrationType: IntegrationType
}

export const ImageReturnTab = ({ userIntegrationId, userIntegrationType }: Props): ReactElement | null => {
	const { t } = useTranslation()
	const { enqueueSnackbar } = useSnackbar()
	const {
		loading: isLoading,
		data,
		error
	} = useGetUserIntegrationSoftSkillRestitutionQuery({ variables: { userIntegrationId, userIntegrationType } })

	if (isLoading) return <LocalLoader />
	if (error !== undefined || data?.getUserIntegrationSoftSkillRestitution === undefined) {
		enqueueSnackbar(t('sharedReturnTab:error'), { variant: Severities.error })
		return null
	}

	const softSkillRestitutions = data?.getUserIntegrationSoftSkillRestitution ?? []

	if (softSkillRestitutions.length === 0) {
		return <EmptyList label={t('sharedReturnTab:empty-image')} />
	}

	return (
		<Stack spacing={1} sx={{ marginBottom: 10, gap: 2 }}>
			{softSkillRestitutions.map((softSkillRestitution, index) => (
				<SoftSkillRestitutionSection
					key={softSkillRestitution.softSkill.id}
					softSkillRestitution={softSkillRestitution}
					isDefaultOpen={index === 0}
				/>
			))}
		</Stack>
	)
}
