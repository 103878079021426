import { CardList } from '@features/lists/components/CardList'
import { useGetJobSearchListQuery } from '@gqlTypes'
import { Container } from '@mui/material'
import { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { JobSearchCard } from './JobSearchCard'
import { EmptyJobSearchList } from './EmptyJobSearchList'
import { useFilters } from '@shared/hooks/useFilters'
import { ResumeLibraryFilterBar } from './ResumeLibraryFilterBar'
import { useDefaultFilter } from '@shared/hooks/useDefaultFilter'

export const ResumeLibrary = (): ReactElement => {
	const { t } = useTranslation()

	const { filters } = useFilters()
	const variables = { cursor: '', limit: 12, filters }
	const { data, loading, fetchMore, refetch } = useGetJobSearchListQuery({
		variables
	})
	useDefaultFilter({ refetch })

	const result = data?.getJobSearchList
	const jobSearches = result?.nodes ?? []
	const cursor = result?.cursor ?? ''
	const hasNextPage = result?.hasNextPage ?? false
	const count = result?.count ?? 0

	return (
		<Container maxWidth="xl">
			<CardList
				backRoute={'/'}
				toolbar={<ResumeLibraryFilterBar refetch={refetch} />}
				renderItem={(item) => <JobSearchCard jobSearch={item} key={item.id} limit={jobSearches.length} />}
				dataList={jobSearches}
				isLoading={loading}
				title={t('offers:resumeLibrary.title', { count })}
				emptyComponent={<EmptyJobSearchList />}
				cursor={cursor}
				hasNextPage={hasNextPage}
				fetchMore={fetchMore}
				refetch={refetch}
			/>
		</Container>
	)
}
