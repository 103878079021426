import { useTranslation } from 'react-i18next'
import useUserBContext from '@shared/hooks/useUserBContext'
import { type GetInterviewDetailsQuery } from '@gqlTypes'
import { PresentationLinkCard } from './PresentationLinkCard'
import { Masonry } from '@mui/lab'
import { PersonalityProfileCard } from '@shared/components/cards/PersonalityProfileCard'
import { CommunicationKeyCard } from '@shared/components/cards/CommunicationKeyCard'
import { InterviewAttachments } from '../attachment/InterviewAttachments'
import { InterviewSection } from './InterviewSection'
import { KeyInformationsCard } from './KeyInformationsCard'
import { type ReactElement } from 'react'
import { MandatoryStepAlert } from './interviewEvaluation/MandatoryStepAlert'
import { useInterviewState } from './useInterviewState'

interface Props {
	interview: GetInterviewDetailsQuery['getInterview']
	connectedUserId: string
}

export const InterviewInformation = ({ interview, connectedUserId }: Props): ReactElement | null => {
	const { t } = useTranslation()
	const userBContext = useUserBContext()
	const { isMissingProfileStep } = useInterviewState(interview)
	const userIntegration = interview.userIntegration
	const track = interview.track
	const organization = userIntegration.organization
	const user = userIntegration.user
	const personalityTest = user?.personalityTest ?? []
	const profilingId = user?.profilingId ?? ''
	const { avatar, profile, highestDimensions } = personalityTest[0] ?? {
		avatar: {},
		profile: undefined,
		highestDimensions: ''
	}
	const videoConferenceLink =
		interview.videoConferenceLink !== '' ? interview.videoConferenceLink : userBContext.visioLink
	const information = interview.information
	const sharedProfile = interview.userIntegration.sharing?.sharedProfile ?? false

	return (
		<InterviewSection
			title={t('interview:informations.section')}
			right={<MandatoryStepAlert isMissing={isMissingProfileStep} />}
		>
			<Masonry columns={2} spacing={3}>
				<KeyInformationsCard
					category={track.category ?? ''}
					registerDateLabel={userIntegration.registerDateLabel ?? ''}
					publicTitle={userIntegration.user?.publicTitle ?? ''}
					information={information}
					visioLink={videoConferenceLink}
					isEnglish={userIntegration.english ?? false}
					comment={userIntegration.comment ?? ''}
				/>
				{sharedProfile ? (
					<PersonalityProfileCard avatar={avatar} profile={profile} highestDimensions={highestDimensions} />
				) : null}
				{(organization?.isInterviewAttachmentActive ?? false) ? (
					<InterviewAttachments {...{ interview, connectedUserId }} />
				) : null}
				{sharedProfile ? <CommunicationKeyCard profilingId={profilingId} profileId={profile?.id} /> : null}
				<PresentationLinkCard link={user?.link ?? ''} />
			</Masonry>
		</InterviewSection>
	)
}
