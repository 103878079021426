import { Box, Button, type ButtonProps, Tooltip } from '@mui/material'
import { type ReactNode, type ReactElement } from 'react'

interface Props extends ButtonProps {
	tooltipLabel: string
	children?: ReactNode | string
}

export const ButtonWithTooltip = ({ tooltipLabel, children, ...buttonProps }: Props): ReactElement => {
	return (
		<Tooltip title={tooltipLabel}>
			{/* Box is needed to trigger tooltips on disabled button */}
			<Box>
				<Button {...buttonProps}>{children}</Button>
			</Box>
		</Tooltip>
	)
}
