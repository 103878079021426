import { useGetInterviewObjectivesQuery } from '@gqlTypes'
import { LocalLoader } from '@shared/components/layout/LocalLoader'
import CardLayout from '@ui/core/CardLayout'
import { type ReactElement } from 'react'
import { InterviewSection } from '../details/InterviewSection'
import { useToggle } from '@shared/hooks/useToggle'
import { InterviewObjectivesCreationModal } from './edition/InterviewObjectivesCreationModal'
import { InterviewObjectiveList } from './list/InterviewObjectiveList'
import { Button, Stack, Typography } from '@mui/material'
import { useCustomTranslation } from '#translations/useCustomTranslation'

interface Props {
	interviewId: string
	isReadOnly: boolean
}

export const InterviewObjectives = ({ interviewId, isReadOnly }: Props): ReactElement | null => {
	const { t } = useCustomTranslation()
	const [isCreationOpen, toggleIsCreationOpen] = useToggle(false)

	const { loading: isLoading, data } = useGetInterviewObjectivesQuery({ variables: { interviewId } })
	if (isLoading) return <LocalLoader />
	if (data == null) return null
	const objectives = data.getInterviewObjectives
	let suffix = ''
	if (objectives.length === 0) suffix = '_zero'
	if (objectives.length > 1) suffix = '_plural'
	const titleKey = `interview:objectives.title${suffix}`

	return (
		<InterviewSection title={t('interview:objectives.section')} hasAssessorHeadings>
			<CardLayout>
				<Stack direction={'row'} pl={4} pr={4} pt={3} justifyContent={'space-between'} alignItems={'center'}>
					<Typography
						sx={{
							fontWeight: 'bold',
							fontSize: '20px'
						}}
					>
						{t(titleKey, { count: objectives.length })}
					</Typography>
					{isReadOnly ? null : (
						<Button variant="contained" onClick={toggleIsCreationOpen}>
							{t('interview:objectives.add')}
						</Button>
					)}
				</Stack>
				<InterviewObjectiveList objectives={objectives} interviewId={interviewId} isReadOnly={isReadOnly} />
			</CardLayout>
			<InterviewObjectivesCreationModal
				interviewId={interviewId}
				toggleIsOpen={toggleIsCreationOpen}
				isOpen={isCreationOpen}
			/>
		</InterviewSection>
	)
}
