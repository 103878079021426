import { useMeQuery } from '@gqlTypes'
import { type i18n, type TOptions } from 'i18next'
import { useTranslation } from 'react-i18next'

interface UseCustomTranslation {
	t: (translationKey: string, options?: TOptions) => string
	i18n: i18n
}

export const useCustomTranslation = (): UseCustomTranslation => {
	const { t: translate, i18n } = useTranslation()
	const { data } = useMeQuery({ fetchPolicy: 'cache-only' })
	const t = (translationKey: string, options?: TOptions): string => {
		const customKey = data?.me?.organization.customKey ?? ''
		const [domain, path] = translationKey.split(':')
		const customTranslationKey = `customTranslation:${customKey}.${domain}.${path}`
		const key = customKey === '' || !i18n.exists(customTranslationKey) ? translationKey : customTranslationKey

		return translate(key, options)
	}

	return { t, i18n }
}
