import { DiscussionInfoCard } from './DiscussionInfoCard'
import { MessageInfoCard } from '../../shared/components/cards/MessageInfoCard'
import { ConversationFrame } from './conversation/ConversationFrame'
import { useDiscussionContent } from './useDiscussionContent'
import { Grid, Stack } from '@mui/material'
import { type ReactElement } from 'react'
import { type DiscussionUserFragment, usePostMessageFromOrganizationMutation } from '@gqlTypes'
import { DiscussionMessages } from './DiscussionMessages'

interface Props {
	discussionUser?: DiscussionUserFragment
	displayName: string
	photoURL: string
	userId: string
	refetch?: () => void
}

export const DiscussionContent = ({ discussionUser, userId, displayName, photoURL, refetch }: Props): ReactElement => {
	const { messages, isLoadingMessages } = useDiscussionContent(discussionUser)

	const [postMessage, { loading: isSendingMessage }] = usePostMessageFromOrganizationMutation()
	const handleNewMessage = async (messageText: string): Promise<void> => {
		await postMessage({ variables: { recipientId: userId, messageText } })
		if (discussionUser == null && refetch != null) {
			refetch()
		}
	}

	return (
		<>
			<Grid container spacing={2}>
				<Grid item xs={4}>
					<Stack spacing={2}>
						<DiscussionInfoCard photoURL={photoURL} discussionUser={discussionUser} displayName={displayName} />
						{messages.length > 0 && <MessageInfoCard messages={messages} />}
					</Stack>
				</Grid>

				<Grid item xs={8}>
					<ConversationFrame loading={isSendingMessage} onNewMessage={handleNewMessage}>
						<DiscussionMessages displayName={displayName} messages={messages} isLoading={isLoadingMessages} />
					</ConversationFrame>
				</Grid>
			</Grid>
		</>
	)
}
