import { pick } from '#helpers/object.helper'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import { useGqlSnackBar } from '@application/snackbar/useGqlSnackBar'
import { type UpdateMyAccountInput, useMeQuery, useUpdateMyAccountMutation } from '@gqlTypes'
import { useStorage } from '@infrastructure/useStorage'
import { useEffect, useState } from 'react'
import { useForm, type UseFormMethods } from 'react-hook-form'

interface UseUpdateMyAccountFormReturn {
	save: (updateMyAccountInput: UpdateMyAccountInput) => Promise<void>
	isSaving: boolean
	onError: () => void
	formMethods: UseFormMethods<UpdateMyAccountInput>
	setAvatarLocalFile: (file: FileList | null) => void
	avatarLocalFile: FileList | null
}

export const useUpdateMyAccountForm = (): UseUpdateMyAccountFormReturn => {
	const { i18n } = useCustomTranslation()
	const { uploadFiles } = useStorage()
	const [avatarLocalFile, setAvatarLocalFile] = useState<FileList | null>(null)

	const { data: meData } = useMeQuery()
	const me = meData?.me
	const { onError, onCompleted } = useGqlSnackBar('myAccount:toolbar.update')
	const [update, { loading: isSaving }] = useUpdateMyAccountMutation({ onError, onCompleted })

	const defaultValues: UpdateMyAccountInput = {
		firstname: '',
		language: '',
		lastname: '',
		publicTitle: '',
		photoURL: ''
	}

	const formMethods = useForm<UpdateMyAccountInput>({ mode: 'onChange', defaultValues })
	const { reset } = formMethods

	const save = async (updateMyAccountInput: UpdateMyAccountInput): Promise<void> => {
		reset(updateMyAccountInput)
		if (avatarLocalFile != null) {
			const result = await uploadFiles(avatarLocalFile, `users/${me?.id ?? ''}`)
			updateMyAccountInput.photoURL = result.newFiles[0].fileUrl
		}
		await update({ variables: { updateMyAccountInput } })
		await i18n.changeLanguage(updateMyAccountInput.language)
	}

	useEffect(() => {
		if (me != null) {
			reset({
				...pick(me, 'publicTitle', 'photoURL'),
				firstname: me.firstname ?? '',
				language: me.language ?? '',
				lastname: me.lastname ?? ''
			})
		}
	}, [reset, me])

	return {
		save,
		isSaving,
		onError,
		formMethods,
		setAvatarLocalFile,
		avatarLocalFile
	}
}
