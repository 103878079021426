import { type ReactElement } from 'react'
import { Chip, Stack, Typography, type TypographyProps } from '@mui/material'
import { DateTime } from 'luxon'
import EventIcon from '@mui/icons-material/Event'
import { useTranslation } from 'react-i18next'

interface Props {
	targetDate: string
	interviewDate: string
	isScheduled: boolean
	isMissedInterview?: boolean
	interviewStepState: boolean
	hasStartTime: boolean
	typographySx?: TypographyProps
	hasIcon?: boolean
	label?: string
}

export const InterviewTargetDate = ({
	targetDate,
	interviewDate,
	isScheduled,
	isMissedInterview = false,
	interviewStepState,
	hasStartTime,
	typographySx = {},
	hasIcon = true,
	label = ''
}: Props): ReactElement => {
	const { t } = useTranslation()
	const interviewDateTime = DateTime.fromISO(interviewDate)
	const targetDateTime = DateTime.fromISO(targetDate)
	const isLate = targetDateTime.diffNow('days').days < -1 && !interviewStepState
	const dateFormat = hasStartTime ? 'dd/MM/yyyy HH:mm' : 'dd/MM/yyyy'

	return (
		<Stack direction="row" gap={1} alignItems="center">
			{hasIcon ? <EventIcon sx={{ width: 18 }} /> : null}
			{label !== '' ? (
				<Typography sx={{ fontSize: '14px', color: 'grays.gray3', ...typographySx }}>{label}</Typography>
			) : null}
			<Typography sx={{ fontSize: '14px', color: 'grays.gray3', ...typographySx }}>
				{(isScheduled ? '' : t('interviews:before')) + interviewDateTime.toFormat(dateFormat)}
			</Typography>
			{isMissedInterview ? (
				<Chip color="warning" size="small" label={t('interviews:chip.absent')} sx={{ color: '#FFF' }} />
			) : null}
			{isLate ? <Chip color="error" size="small" label={t('interviews:chip.late')} sx={{ color: '#FFF' }} /> : null}
		</Stack>
	)
}
