import { type ReactNode, type ReactElement } from 'react'
import { InfoLayoutLabel } from '@ui/core/InfoLayoutLabel'
import { InfoLayoutValue } from '@ui/core/InfoLayoutValue'
import { TableRow } from '@mui/material'
import { TypographyWithLink } from '@ui/typography/TypographyWithLink'

interface Props {
	title: string
	children?: ReactNode
	icon?: ReactElement
	labelSize?: number | string
	text?: string
	url?: string
}
export default function InfoValue({ title, children, icon, labelSize, text = '', url }: Props): JSX.Element | null {
	if (children == null && text === '') return null
	return (
		<TableRow>
			<InfoLayoutLabel icon={icon} labelSize={labelSize}>
				{title}
			</InfoLayoutLabel>
			<InfoLayoutValue>
				{children}
				{text !== '' ? <TypographyWithLink text={text} url={url} /> : null}
			</InfoLayoutValue>
		</TableRow>
	)
}
