import { type ReactElement, useState } from 'react'
import { InfoCardContent } from '@ui/core/InfoCardContent'
import Avatar from '@mui/material/Avatar'
import Divider from '@mui/material/Divider'
import { CardMenuButton } from '@ui/core/CardMenuButton'
import { BooleanInput } from '@ui/core/BooleanInput'
import ChatIcon from '@mui/icons-material/Chat'
import FlagIcon from '@mui/icons-material/Flag'
import ArchiveIcon from '@mui/icons-material/Archive'
import ViewListIcon from '@mui/icons-material/ViewList'
import Collapse from '@mui/material/Collapse'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import CardLayout from '@ui/core/CardLayout'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@mui/material'
import { type DiscussionUserFragment, useUpdateDiscussionUserMutation } from '@gqlTypes'
import { generateRoute, ROUTE_CANDIDATE_DETAIL } from '../../router/routes'
import { useHistory } from 'react-router-dom'

interface Props {
	discussionUser?: DiscussionUserFragment
	photoURL: string
	displayName: string
}

export const DiscussionInfoCard = ({ discussionUser, photoURL, displayName }: Props): ReactElement => {
	const { t } = useTranslation(['translation', 'userOfferStatus'])
	const theme = useTheme()
	const [isExpanded, setIsExpanded] = useState(false)
	const [updateDiscussionUser] = useUpdateDiscussionUserMutation()
	const history = useHistory()
	const onCandidacySelect = (userOfferId: string): void => {
		history.push(generateRoute(ROUTE_CANDIDATE_DETAIL, { userOfferId }))
	}

	const isArchived = discussionUser?.archived ?? false
	const isFlagged = discussionUser?.flagged ?? false
	const isRead = discussionUser?.read ?? false
	const disabled = discussionUser == null

	return (
		<CardLayout>
			<InfoCardContent
				style={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'flex-start',
					alignItems: 'center'
				}}
			>
				{photoURL !== '' && <Avatar sx={{ fontSize: 50, width: 150, height: 150 }} src={photoURL} />}
				<Typography>{displayName}</Typography>
			</InfoCardContent>
			<Divider />
			{disabled ? (
				<>
					<CardMenuButton simple>
						<BooleanInput icon={<ChatIcon />} label={t('translation:discussionDetailPage.read.false')} readOnly />
					</CardMenuButton>
					<Divider />
					<CardMenuButton simple>
						<BooleanInput icon={<FlagIcon />} label={t('translation:discussionDetailPage.flagged.false')} readOnly />
					</CardMenuButton>
					<Divider />
					<CardMenuButton simple>
						<BooleanInput
							icon={<ArchiveIcon />}
							label={t('translation:discussionDetailPage.archived.false')}
							readOnly
						/>
					</CardMenuButton>
					<CardMenuButton>
						<BooleanInput
							label={t('translation:discussionDetailPage.candidacyList')}
							icon={<ViewListIcon />}
							readOnly
						/>
					</CardMenuButton>
				</>
			) : (
				<>
					<CardMenuButton
						onClick={async () => {
							await updateDiscussionUser({
								variables: { id: discussionUser.id ?? '', discussionUserInput: { read: !isRead } }
							})
						}}
						simple
					>
						<BooleanInput
							icon={<ChatIcon />}
							checkedIcon={<ChatIcon style={{ color: theme.palette.darkBlue.light }} />}
							trueLabel={t('translation:discussionDetailPage.read.true')}
							falseLabel={t('translation:discussionDetailPage.read.false')}
							value={isRead}
							readOnly
						/>
					</CardMenuButton>
					<Divider />
					<CardMenuButton
						onClick={async () => {
							await updateDiscussionUser({
								variables: { id: discussionUser.id ?? '', discussionUserInput: { flagged: !isFlagged } }
							})
						}}
						simple
					>
						<BooleanInput
							icon={<FlagIcon />}
							checkedIcon={
								<FlagIcon
									style={{
										color: theme.palette.error.main
									}}
								/>
							}
							trueLabel={t('translation:discussionDetailPage.flagged.true')}
							falseLabel={t('translation:discussionDetailPage.flagged.false')}
							value={isFlagged}
							readOnly
						/>
					</CardMenuButton>
					<Divider />
					<CardMenuButton
						onClick={async () => {
							await updateDiscussionUser({
								variables: { id: discussionUser.id ?? '', discussionUserInput: { archived: !isArchived } }
							})
						}}
						simple
					>
						<BooleanInput
							icon={<ArchiveIcon />}
							checkedIcon={
								<ArchiveIcon
									style={{
										color: theme.palette.warning.main
									}}
								/>
							}
							trueLabel={t('translation:discussionDetailPage.archived.true')}
							falseLabel={t('translation:discussionDetailPage.archived.false')}
							value={isArchived}
							readOnly
						/>
					</CardMenuButton>
					<Divider />
					<CardMenuButton
						onClick={() => {
							setIsExpanded(!isExpanded)
						}}
					>
						<BooleanInput
							label={t('translation:discussionDetailPage.candidacyList')}
							icon={<ViewListIcon />}
							readOnly
						/>
					</CardMenuButton>
					<Collapse in={isExpanded}>
						{discussionUser.userOffers.map((userOffer) => (
							<div key={userOffer.id}>
								<CardMenuButton
									onClick={() => {
										onCandidacySelect(userOffer.id)
									}}
								>
									<Typography>
										<Box fontWeight="fontWeightBold">{userOffer.orgaOffer?.label}</Box>
										<Box>{t(`userOfferStatus:${userOffer.status}.labelOrga`)}</Box>
									</Typography>
								</CardMenuButton>
							</div>
						))}
					</Collapse>
				</>
			)}
		</CardLayout>
	)
}
