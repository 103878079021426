import { TableCell, Stack } from '@mui/material'
import Typography from '@mui/material/Typography'
import { type ReactElement, type ReactNode } from 'react'

interface Props {
	icon?: ReactNode
	children: ReactNode
	light?: boolean
	verticalAlign?: string
	labelSize?: string | number
	required?: boolean
}

export const InfoLayoutLabel = ({
	icon,
	children,
	light = false,
	verticalAlign = 'top',
	labelSize,
	required = false
}: Props): ReactElement => {
	const fontWeight = light ? 'lighter' : 'bold'

	return (
		<TableCell sx={{ verticalAlign, width: labelSize ?? null }}>
			<Stack direction="row" spacing={1}>
				<Typography color="primary">{icon}</Typography>
				<Typography color="primary" sx={{ fontWeight, whiteSpace: labelSize == null ? 'pre' : 'normal' }}>
					<Stack direction={'row'}>
						{children}
						{required ? <Typography color="error"> *</Typography> : null}
					</Stack>
				</Typography>
			</Stack>
		</TableCell>
	)
}
