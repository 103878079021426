import { type ChangeEvent, type ReactElement, useRef } from 'react'
import { Avatar, Badge, Button } from '@mui/material'
import TableLayoutRow from '@shared/components/cards/TableLayoutRow'
import EditIcon from '@mui/icons-material/Edit'
import { useController } from 'react-hook-form'

interface Props {
	onSelectFile: (files: FileList | null) => void
	avatarLocalFile: FileList | null
}

export const AvatarField = ({ onSelectFile, avatarLocalFile }: Props): ReactElement => {
	const fileInputRef = useRef(null)
	const {
		field: { value }
	} = useController({ name: 'photoURL' })

	const handleFileChange = (event: ChangeEvent<HTMLInputElement>): void => {
		onSelectFile(event.target.files)
	}

	const file = avatarLocalFile != null ? avatarLocalFile.item(0) : null
	const avatarUrl = file != null ? URL.createObjectURL(file) : value

	return (
		<TableLayoutRow translationKey={'myAccount:generalInformations.fields.avatar'} justifyContent="center">
			<Button sx={{ borderRadius: 15 }} component="label">
				<input ref={fileInputRef} hidden accept={'image/*'} multiple={false} type="file" onChange={handleFileChange} />
				<Badge
					badgeContent={<EditIcon sx={{ height: 20, width: 20 }} />}
					color="primary"
					anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
					overlap="circular"
					sx={{ '& .MuiBadge-badge': { height: 30, width: 30, borderRadius: 10 } }}
				>
					<Avatar sx={{ width: 120, height: 120 }} src={avatarUrl} />
				</Badge>
			</Button>
		</TableLayoutRow>
	)
}
