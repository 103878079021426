import { type ReactElement } from 'react'
import { useDocumentTitle } from '@shared/hooks/useDocumentTitle'
import { useTranslation } from 'react-i18next'
import { useGetUserOfferJobMatchingQuery } from '@gqlTypes'
import { Container } from '@mui/material'
import { CardList } from '@features/lists/components/CardList'
import { JobMatchingListToolbar } from './JobMatchingListToolbar'
import { JobMatchingCard } from './JobMatchingCard'
import { EmptyJobMatchingList } from './EmptyJobMatchingList'
import { useParams } from 'react-router-dom'
import { useGetOfferTitle } from './useGetOfferTitle'

export const JobMatchingList = (): ReactElement => {
	const { trackOfferId = '', statusId = '' } = useParams<{ trackOfferId: string; statusId: string }>()
	const { t } = useTranslation()
	useDocumentTitle(t('offers:documentTitle'))
	const variables = { trackOfferId, statusId }
	const { loading, data } = useGetUserOfferJobMatchingQuery({ variables })
	const jobMatchings = data?.getUserOfferJobMatching ?? []
	const label = useGetOfferTitle(trackOfferId)

	return (
		<Container maxWidth="xl">
			<CardList
				backRoute={'/offers'}
				toolbar={<JobMatchingListToolbar {...{ trackOfferId, statusId }} />}
				renderItem={(item) => <JobMatchingCard {...{ trackOfferId, statusId }} key={item.jobMatching.id} {...item} />}
				dataList={jobMatchings}
				isLoading={loading}
				title={label}
				emptyComponent={<EmptyJobMatchingList />}
			/>
		</Container>
	)
}
