import { type ReactElement } from 'react'
import { Container, Typography } from '@mui/material'
import { CardList } from '@features/lists/components/CardList'
import { ArticleCard } from './ArticleCard'
import { t } from 'i18next'
import { type GetArticlesQuery, useGetArticlesQuery } from '@gqlTypes'
import { useDefaultFilter } from '@shared/hooks/useDefaultFilter'

export const ArticleList = (): ReactElement => {
	let cursor = ''
	let hasNextPage = false
	let count = 0
	const variables = { limit: 10, filters: [], cursor }
	const { loading, data, refetch, fetchMore } = useGetArticlesQuery({
		variables,
		fetchPolicy: 'cache-and-network'
	})
	useDefaultFilter({ refetch })

	let nodes: GetArticlesQuery['getArticles']['nodes'][0][] = []
	if (data?.getArticles != null) {
		;({ nodes, count } = data.getArticles)
		cursor = data?.getArticles.cursor ?? ''
		hasNextPage = data?.getArticles.hasNextPage ?? false
	}

	return (
		<Container maxWidth="xl">
			<CardList
				renderItem={(item) => <ArticleCard key={item?.id} article={item} />}
				dataList={nodes}
				isLoading={loading}
				fetchMore={fetchMore}
				cursor={cursor}
				hasNextPage={hasNextPage}
				emptyComponent={<Typography sx={{ fontSize: 24 }}>{t('article:dataGrid.empty')}</Typography>}
				title={t('article:title', { count })}
			/>
		</Container>
	)
}
