import { type ReactElement, useState } from 'react'
import Avatar from '@mui/material/Avatar'
import { teal } from '@mui/material/colors'
import { Badge, Box, Divider, IconButton, Popover, List } from '@mui/material'
import { firebaseAuth } from '@infrastructure/firebase/firebase.helper'
import MoreHoriz from '@mui/icons-material/MoreHoriz'
import { useMeQuery, type OrganizationListItem } from '@gqlTypes'
import { UserAccount } from './UserAccount'
import { UserActions } from './UserActions'

interface Props {
	organizationList: OrganizationListItem[]
	currentOrganizationId: string
}

const UserToolbar = ({ organizationList, currentOrganizationId }: Props): ReactElement => {
	const currentUser = firebaseAuth().currentUser
	const { data } = useMeQuery()
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
		setAnchorEl(event.currentTarget)
	}
	const handleClose = (): void => {
		setAnchorEl(null)
	}
	const open = Boolean(anchorEl)
	const id = open ? 'simple-popover' : undefined

	return (
		<Box sx={{ ml: 1, mb: 2 }}>
			<IconButton aria-describedby={id} onClick={handleClick}>
				<Badge
					sx={{ '& .MuiBadge-badge': { height: '14px', minWidth: '10px', padding: '2px' } }}
					color="primary"
					badgeContent={<MoreHoriz sx={{ width: 10, height: 10 }} />}
					overlap="circular"
					anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
				>
					<Avatar sx={{ backgroundColor: teal[200] }} src={data?.me?.photoURL}>
						{currentUser?.displayName != null ? currentUser.displayName.substring(0, 1).toUpperCase() : 'U'}
					</Avatar>
				</Badge>
			</IconButton>
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				sx={{ borderRadius: 2, ml: 1 }}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right'
				}}
				transformOrigin={{
					vertical: 'bottom',
					horizontal: 'left'
				}}
			>
				<List sx={{ minWidth: 300 }} disablePadding>
					<UserAccount organizationList={organizationList} />
					<Divider />
					<UserActions
						organizationList={organizationList}
						currentOrganizationId={currentOrganizationId}
						handleClose={handleClose}
					/>
				</List>
			</Popover>
		</Box>
	)
}

export default UserToolbar
