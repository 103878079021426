import { type ReactElement } from 'react'
import { FormControl, FormControlLabel, Radio, RadioGroup, type RadioGroupProps } from '@mui/material'
import { useController } from 'react-hook-form'

interface Props extends RadioGroupProps {
	name: string
	items: Item[]
	isEditable?: boolean
	defaultValue?: string
}
interface Item {
	id: string
	label: string
}

export const RadioSelector = ({ name, items, defaultValue = '', ...radioGroupProps }: Props): ReactElement | null => {
	const {
		field: { onChange, value }
	} = useController<string[]>({
		defaultValue,
		name
	})

	return (
		<FormControl>
			<RadioGroup value={value} onChange={onChange} {...radioGroupProps}>
				{items.map(({ id, label }) => (
					<FormControlLabel key={id} value={id} control={<Radio />} label={label} />
				))}
			</RadioGroup>
		</FormControl>
	)
}
