import { type UpdateMyAccountInput } from '@gqlTypes'
import TableLayoutRow from '@shared/components/cards/TableLayoutRow'
import InputTextField from '@ui/form/inputTextField'
import { type ReactElement } from 'react'
import { useFormContext } from 'react-hook-form'

export const FirstnameField = (): ReactElement => {
	const { errors } = useFormContext<UpdateMyAccountInput>()
	const name = 'firstname'
	return (
		<TableLayoutRow translationKey={`myAccount:generalInformations.fields.${name}`} required>
			<InputTextField name={name} required error={errors[name]} />
		</TableLayoutRow>
	)
}
