import { type InterviewQuestionResponse } from '@gqlTypes'
import { ChipList } from '@shared/components/ChipList'
import { RadioSelector } from '@ui/form/RadioSelector'
import { type ReactElement } from 'react'

interface Props {
	id: string
	isEditable: boolean
	answer: string
	responses: InterviewQuestionResponse[]
}

export const InterviewUniqueSelectQuestion = ({ id, isEditable, answer, responses }: Props): ReactElement => {
	const items = responses.filter(({ id }) => id === answer)
	return (
		<>
			{isEditable ? (
				<RadioSelector name={`answers[${id}]`} items={responses} defaultValue={answer} row sx={{ ml: 5 }} />
			) : (
				<ChipList sx={{ ml: 5 }} items={items} selectedItems={[answer]} isEditable={false} />
			)}
		</>
	)
}
