import { useCustomTranslation } from '#translations/useCustomTranslation'
import { useGetSupportedLanguagesQuery } from '@gqlTypes'
import TableLayoutRow from '@shared/components/cards/TableLayoutRow'
import { AutocompleteSelect } from '@ui/form/AutocompleteSelect'
import { type ReactElement } from 'react'
import { useFormContext } from 'react-hook-form'

export const LanguageField = (): ReactElement => {
	const { t } = useCustomTranslation()
	const { errors } = useFormContext()

	const { data, loading: isLoading } = useGetSupportedLanguagesQuery()
	const items = (data?.getSupportedLanguages ?? []).map((item) => ({
		value: item.id,
		label: item.label
	}))
	const name = 'language'
	const hasError = errors[name] != null
	const errorMessage = errors[name]?.message ?? ''

	return (
		<TableLayoutRow translationKey={`myAccount:generalInformations.fields.${name}`} required>
			<AutocompleteSelect
				name={name}
				items={items}
				loading={isLoading}
				rules={{ required: t('form:errors.required') }}
				hasError={hasError}
				errorMessage={errorMessage}
				inputProps={{ size: 'small' }}
			/>
		</TableLayoutRow>
	)
}
