import { ScreenId } from '@gqlTypes'
import { MyProfilePages } from './MyProfile.pages'
import { MyAccount } from './account/MyAccount.page'

enum Path {
	resume = '/my-profile',
	developmentsAxis = '/my-profile/development-axis',
	myFeedbacks = '/my-profile/my-feedbacks',
	account = '/my-account'
}

const routes = [
	{
		path: Path.resume,
		children: <MyProfilePages />,
		notExact: true
	},
	{
		path: Path.account,
		children: <MyAccount />
	}
]

export const myProfileRouter = {
	roleId: ScreenId.MyProfile,
	path: Path,
	routes
}
