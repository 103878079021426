import { useCustomTranslation } from '#translations/useCustomTranslation'
import { LoadingButton } from '@mui/lab'
import { Button, Grid, Stack } from '@mui/material'
import { useDocumentTitle } from '@shared/hooks/useDocumentTitle'
import CardLayout from '@ui/core/CardLayout'
import { TableLayout } from '@ui/core/TableLayout'
import { ExitFormWarning } from '@ui/form/ExitFormWarning'
import { DefaultLayout } from '@ui/layout/DefaultLayout'
import { type ReactElement } from 'react'
import { FormProvider } from 'react-hook-form'
import { useUpdateMyAccountForm } from './useUpdateMyAccountForm'
import { FirstnameField } from './FirstnameField'
import { LastnameField } from './LastnameField'
import { PublicTitleField } from './PublicTitleField'
import { LanguageField } from './LanguageField'
import { AvatarField } from '@domains/myProfile/account/AvatarField'

export const MyAccount = (): ReactElement => {
	const { t } = useCustomTranslation()
	useDocumentTitle(t('myAccount:title'))

	const { save, isSaving, onError, formMethods, setAvatarLocalFile, avatarLocalFile } = useUpdateMyAccountForm()
	const {
		handleSubmit,
		reset,
		formState: { isDirty }
	} = formMethods

	const isActionsDisabled = !isDirty && avatarLocalFile == null

	return (
		<DefaultLayout
			title={t('myAccount:title')}
			right={
				<Stack direction="row" spacing={3}>
					<Button
						disabled={isActionsDisabled}
						variant="contained"
						onClick={() => {
							reset()
							setAvatarLocalFile(null)
						}}
					>
						{t('myAccount:toolbar.cancel')}
					</Button>
					<LoadingButton
						disabled={isActionsDisabled}
						loading={isSaving}
						onClick={handleSubmit(save, onError)}
						variant="contained"
						color="secondary"
					>
						{t('myAccount:toolbar.update.label')}
					</LoadingButton>
				</Stack>
			}
		>
			<FormProvider {...formMethods}>
				<ExitFormWarning isModified={isDirty} />
				<Grid container spacing={3} mb={3} mt={0}>
					<Grid item xs={6}>
						<CardLayout title={t('myAccount:generalInformations.title')}>
							<TableLayout>
								<FirstnameField />
								<LastnameField />
								<LanguageField />
								<PublicTitleField />
								<AvatarField onSelectFile={setAvatarLocalFile} avatarLocalFile={avatarLocalFile} />
							</TableLayout>
						</CardLayout>
					</Grid>
				</Grid>
			</FormProvider>
		</DefaultLayout>
	)
}
