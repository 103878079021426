import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import { type ReactNode, type ReactElement } from 'react'
import { type SxProps } from '@mui/material'

interface Props {
	image: string
	children: ReactNode
	pictureStyle: SxProps
	cardStyle?: SxProps
}

export const PictureCard = ({ image, children, pictureStyle, cardStyle }: Props): ReactElement => {
	return (
		<Card sx={cardStyle}>
			<CardMedia sx={pictureStyle} image={image} />
			<CardContent>{children}</CardContent>
		</Card>
	)
}
