import CardLayout from '@ui/core/CardLayout'
import { InfoCardContent } from '@ui/core/InfoCardContent'
import Stack from '@mui/material/Stack'
import { Question } from './Question'
import { type QuestionWithAnswer } from './QuestionWithAnswer'
import { type ReactElement } from 'react'

interface Props {
	questions: QuestionWithAnswer[]
	isEditable: boolean
	title: string
}

export const QuestionCard = ({ questions, isEditable, title }: Props): ReactElement | null => {
	if (questions.length === 0) return null

	return (
		<CardLayout title={title} className="question">
			<InfoCardContent>
				<Stack spacing={2}>
					{questions.map((question) => (
						<Question key={question.id} {...question} isEditable={isEditable} />
					))}
				</Stack>
			</InfoCardContent>
		</CardLayout>
	)
}
