import { type ReactElement } from 'react'
import { Dialog, DialogContent, DialogTitle, Stack } from '@mui/material'
import { FormProvider, useForm } from 'react-hook-form'
import {
	type InterviewObjectiveInput,
	ObjectiveTypeId,
	useCreateInterviewObjectiveMutation,
	ObjectiveStatusId,
	GetInterviewObjectivesDocument
} from '@gqlTypes'
import { useGqlSnackBar } from '@application/snackbar/useGqlSnackBar'
import { DialogCloseButton } from '@ui/dialog/DialogCloseButton'
import { InterviewObjectivesForm } from './InterviewObjectivesForm'
import { LoadingButton } from '@mui/lab'
import { useCustomTranslation } from '#translations/useCustomTranslation'

interface Props {
	isOpen: boolean
	toggleIsOpen: () => void
	interviewId: string
}

export const InterviewObjectivesCreationModal = ({ isOpen, toggleIsOpen, interviewId }: Props): ReactElement => {
	const { t } = useCustomTranslation()
	const key = 'interview:objectives.modal.creation'
	const { onError } = useGqlSnackBar(key)

	const defaultValues: InterviewObjectiveInput = {
		label: '',
		description: '',
		quantity: {
			completion: 0,
			value: 0,
			target: 0,
			unit: ''
		},
		quality: {
			completion: 0,
			target: '',
			nextTarget: ''
		},
		statusId: ObjectiveStatusId.Defined,
		comment: '',
		typeId: ObjectiveTypeId.Quantity
	}

	const [createObjective, { loading: isSaving }] = useCreateInterviewObjectiveMutation({
		onError
	})

	const formMethods = useForm({ defaultValues, mode: 'onChange' })
	const onSubmit = async (input: InterviewObjectiveInput): Promise<void> => {
		await createObjective({ variables: { input, interviewId }, refetchQueries: [GetInterviewObjectivesDocument] })
		toggleIsOpen()
	}

	return (
		<Dialog open={isOpen} fullWidth maxWidth="lg" onClose={toggleIsOpen} PaperProps={{ sx: { borderRadius: 3 } }}>
			<DialogTitle>{t(`${key}.title`)}</DialogTitle>
			<DialogCloseButton onClick={toggleIsOpen} />
			<DialogContent>
				<FormProvider {...formMethods}>
					<InterviewObjectivesForm onSubmit={onSubmit}>
						<Stack
							sx={{
								flexDirection: 'column',
								alignItems: 'center'
							}}
						>
							<LoadingButton variant="contained" type="submit" loading={isSaving} sx={{ maxWidth: 150 }}>
								{t(`${key}.save`)}
							</LoadingButton>
						</Stack>
					</InterviewObjectivesForm>
				</FormProvider>
			</DialogContent>
		</Dialog>
	)
}
