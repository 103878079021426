import { useCustomTranslation } from '#translations/useCustomTranslation'
import { Stack } from '@mui/material'
import { InputTextFieldForm } from '@ui/form/inputTextFieldForm'
import { type ReactElement } from 'react'

interface Props {
	isReadOnly: boolean
}

export const QuantityLeftFields = ({ isReadOnly }: Props): ReactElement => {
	const { t } = useCustomTranslation()
	const key = 'interview:objectives.modal'
	return (
		<Stack direction={'row'} gap={2}>
			<InputTextFieldForm
				disabled={isReadOnly}
				name="quantity.target"
				label={t(`${key}.target`)}
				type="number"
				fullWidth
			/>
			<InputTextFieldForm disabled={isReadOnly} name="quantity.unit" label={t(`${key}.unit`)} fullWidth />
		</Stack>
	)
}
