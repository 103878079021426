import { DateTime } from 'luxon'
import { useTranslation } from 'react-i18next'

interface UseDate {
	fullDate: (isoDate: string) => string
	shortDate: (isoDate: string) => string
	MedDate: (isoDate: string) => string
}

export const useDate = (): UseDate => {
	const { i18n } = useTranslation()
	const fullDate = (isoDate: string): string => {
		return DateTime.fromISO(isoDate).setLocale(i18n.language).toLocaleString(DateTime.DATE_FULL)
	}
	const shortDate = (isoDate: string): string => {
		return DateTime.fromISO(isoDate).setLocale(i18n.language).toLocaleString(DateTime.DATE_SHORT)
	}
	const MedDate = (isoDate: string): string => {
		return DateTime.fromISO(isoDate).setLocale(i18n.language).toLocaleString(DateTime.DATE_MED)
	}
	return { fullDate, shortDate, MedDate }
}
