import { Box, Tooltip } from '@mui/material'
import { ConfirmButton } from '@ui/buttons/ConfirmButton'
import { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
	isSaving: boolean
	signInterview: () => void
}

export const InterviewSignatureButton = ({ isSaving, signInterview }: Props): ReactElement => {
	const { t } = useTranslation()
	return (
		<div style={{ display: 'flex', justifyContent: 'center' }}>
			<Tooltip title={t('interview:signatures.popup.tooltip')}>
				<Box>
					<ConfirmButton
						isLoading={isSaving}
						onPressConfirm={signInterview}
						title={t('interview:signatures.popup.title')}
						message={t('interview:signatures.popup.message')}
						confirmLabel={t('interview:signatures.popup.confirmLabel')}
						cancelLabel={t('interview:signatures.popup.cancelLabel')}
					/>
				</Box>
			</Tooltip>
		</div>
	)
}
