import { useCustomTranslation } from '#translations/useCustomTranslation'
import { IntegrationType, JobMatchingStatusId, useMeQuery, type GetPublicResumeQuery } from '@gqlTypes'
import { ButtonWithTooltip } from '@ui/buttons/ButtonWithTooltip'
import { useState, type ReactElement } from 'react'
import { JobmatchingInterviewLink } from './JobmatchingInterviewLink'
import { NewInterviewDrawer } from '@domains/interviews/create/NewInterviewDrawer'
import { LocalLoader } from '@shared/components/layout/LocalLoader'

interface Props {
	jobMatching: GetPublicResumeQuery['getUserOfferJobMatching'][0]['jobMatching']
	refetch: () => void
}

export const JobMatchingInterview = ({ jobMatching, refetch }: Props): ReactElement | null => {
	const { data: meData, loading: isMeLoading } = useMeQuery()
	const { t } = useCustomTranslation()
	const [isOpen, setIsOpen] = useState(false)

	const interview = jobMatching?.candidateInterview
	const candidateUserOfferId = jobMatching?.candidateUserOfferId ?? ''
	const jobMatchingStatus = jobMatching?.status

	const hasInterviewCreationButton = candidateUserOfferId !== '' && (interview?.id ?? '') === ''
	const isCreationButtonDisabled = jobMatchingStatus.id !== JobMatchingStatusId.Applied
	const interviewButtonTooltipLabel = t(
		`offers:jobMatching.browser.interview.create.tooltip.${isCreationButtonDisabled ? 'disabled' : 'enabled'}`
	)

	if (isMeLoading) return <LocalLoader />
	if (meData == null || !jobMatching.hasOrganizationAccess) return null

	return (
		<>
			{hasInterviewCreationButton ? (
				<ButtonWithTooltip
					disabled={isCreationButtonDisabled}
					variant="contained"
					color="secondary"
					onClick={() => {
						setIsOpen(true)
					}}
					tooltipLabel={interviewButtonTooltipLabel}
				>
					{t('offers:jobMatching.browser.interview.create.label')}
				</ButtonWithTooltip>
			) : null}
			<JobmatchingInterviewLink interview={interview} />
			<NewInterviewDrawer
				setIsOpen={setIsOpen}
				isOpen={isOpen}
				userIntegrationInput={{ type: IntegrationType.Offer, id: candidateUserOfferId }}
				assessorId={meData.me?.id ?? ''}
				reset={refetch}
			/>
		</>
	)
}
