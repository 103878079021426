import { Typography } from '@mui/material'
import moment from 'moment'
import { type ReactElement } from 'react'

interface Props {
	name: string
	date: string
}

export const InterviewSignatureDate = ({ name, date }: Props): ReactElement => {
	return (
		<>
			<Typography
				color="primary"
				style={{
					fontFamily: 'Caveat',
					textAlign: 'center',
					fontSize: 30
				}}
			>
				{name}
			</Typography>
			<Typography
				color="primary"
				style={{
					fontFamily: 'Caveat',
					textAlign: 'center',
					fontSize: 22
				}}
			>
				{moment(date).format('[le] DD/MM/YYYY [à] HH[h]mm')}
			</Typography>
		</>
	)
}
