import { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { OrgaSkillSection } from './OrgaSkillSection'
import { Stack, Tooltip, IconButton } from '@mui/material'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import { CampaignSelector } from '@domains/campaigns/CampaignSelector'
import { InputTextFieldForm } from '@ui/form/inputTextFieldForm'
import DatepickerField from '@ui/form/DatepickerField'

export const CustomFeedbackRequestForm = (): ReactElement => {
	const { t } = useTranslation()

	return (
		<>
			<CampaignSelector isRequired />
			<InputTextFieldForm
				rules={{ required: t('form:errors.required') }}
				name="message"
				variant="outlined"
				fullWidth
				multiline
				rows={8}
			/>
			<Stack spacing={3}>
				<OrgaSkillSection />
				<Stack direction={'row'} spacing={1}>
					<DatepickerField
						name="dueDate"
						timestamp={false}
						label={t('modal:massFeedback.dueDate.label')}
						defaultValue={null}
					/>
					<Stack sx={{ justifyContent: 'center' }}>
						<Tooltip title={t('modal:massFeedback.dueDate.tooltip')}>
							<IconButton>
								<HelpOutlineIcon />
							</IconButton>
						</Tooltip>
					</Stack>
				</Stack>
			</Stack>
		</>
	)
}
