import { useEffect, type ReactElement } from 'react'
import { FormHelperText, Stack } from '@mui/material'
import { useController, useFormContext } from 'react-hook-form'
import { LocalLoader } from '@shared/components/layout/LocalLoader'
import { useTranslation } from 'react-i18next'
import { ChipList } from '@shared/components/ChipList'

interface Props {
	name: string
	items: Item[]
	isLoading?: boolean
	maxSelectableItem?: number
	withOrder?: boolean
	minSelectableItem?: number
	isEditable?: boolean
	defaultValue?: string[]
	isSortedAlphabetically?: boolean
	errorMessage?: string
	isRequired?: boolean
}
interface Item {
	id: string
	label: string
	color?: string
	isPositive?: boolean
}

export const ChipSelector = ({
	name,
	items,
	isLoading = false,
	maxSelectableItem = 0,
	withOrder = false,
	minSelectableItem = 0,
	defaultValue = [],
	isEditable = true,
	isSortedAlphabetically = false,
	errorMessage = '',
	isRequired = true
}: Props): ReactElement | null => {
	const {
		field: { onChange, value }
	} = useController<string[]>({
		defaultValue,
		name,
		rules: {
			validate: (array) => {
				const message =
					errorMessage !== '' ? errorMessage : t('form:errors.minSelectableItem', { count: minSelectableItem })
				return array.length >= minSelectableItem || (!isRequired && array.length === 0) ? true : message
			}
		}
	})
	const formContext = useFormContext()
	const { t } = useTranslation()
	const errors = formContext.formState?.errors ?? {}

	useEffect(() => {
		if (formContext.formState.isDirty) onChange([])
	}, [items])

	if (isLoading) return <LocalLoader />

	const computeNewValue = (id: string, value: string[] = []): string[] => {
		const isAlreadySelected = value.includes(id)
		if (isAlreadySelected) return value.filter((value) => value !== id)

		const isMaxSelectableNotReached = maxSelectableItem === 0 || value.length < maxSelectableItem
		if (isMaxSelectableNotReached) return [...value, id]

		return maxSelectableItem === 1 ? [id] : value
	}

	const error = errors[name]

	return (
		<Stack>
			<ChipList
				items={items}
				withOrder={withOrder}
				onValueChange={(itemId) => {
					onChange(computeNewValue(itemId, value as string[]))
				}}
				selectedItems={value}
				isEditable={isEditable}
				isSortedAlphabetically={isSortedAlphabetically}
			/>
			<FormHelperText error>{error?.message ?? ''}</FormHelperText>
		</Stack>
	)
}
