import { LocalDate } from '@ui/core/LocalDate'
import Tooltip from '@mui/material/Tooltip'
import theme from '@ui/theme'
import { type ReactElement } from 'react'

interface Props {
	author?: string
	date?: Date
	children: React.ReactNode
	variant?: 'self' | 'other'
}

export const ConversationMessageContainer = ({ author, date, children, variant = 'self' }: Props): ReactElement => {
	const isSelf = variant === 'self'
	const backgroundColor = isSelf ? theme.palette.messageSelf.main : theme.palette.messageOther.main
	const marginLeft = isSelf ? 0 : 15
	const textAlign = isSelf ? 'right' : 'left'

	return (
		<Tooltip
			enterDelay={500}
			placement={variant === 'self' ? 'right' : 'left'}
			title={
				<>
					{date != null ? <LocalDate date={date} dateStyle="short" timeStyle="short" /> : null}
					{author != null ? ` • ${author}` : null}
				</>
			}
		>
			<div
				style={{
					marginTop: 10,
					marginBottom: 10,
					textAlign
				}}
			>
				<div
					style={{
						boxSizing: 'border-box',
						display: 'inline-block',
						padding: '10px 15px',
						maxWidth: '75%',
						position: 'relative',
						marginLeft,
						zIndex: 1,
						textAlign: 'left',
						backgroundColor,
						borderRadius: 20,
						marginRight: 15,
						borderBottomRightRadius: isSelf ? 0 : 20,
						borderBottomLeftRadius: isSelf ? 20 : 0
					}}
				>
					{children}
				</div>
			</div>
		</Tooltip>
	)
}
