import { useCustomTranslation } from '#translations/useCustomTranslation'
import { InputTextFieldForm } from '@ui/form/inputTextFieldForm'
import { type ReactElement } from 'react'

interface Props {
	isReadOnly: boolean
}

export const QualityLeftFields = ({ isReadOnly }: Props): ReactElement => {
	const { t } = useCustomTranslation()
	const key = 'interview:objectives.modal'
	return <InputTextFieldForm disabled={isReadOnly} name="quality.target" label={t(`${key}.target`)} />
}
