import { type Dispatch, type ReactElement, type SetStateAction, useState } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import Divider from '@mui/material/Divider'
import { useTranslation } from 'react-i18next'
import { DialogSection } from '@shared/components/modal/dialogSection'
import {
	FeedbackRequestMode,
	type IntegrationCategory,
	type TrackType,
	useSendBrandFeedbackRequestMutation
} from '@gqlTypes'
import { Stack, ToggleButton, ToggleButtonGroup } from '@mui/material'
import { useFilters } from '@shared/hooks/useFilters'
import { searchQuery } from '@features/lists/components/Search'
import { LoadingButton } from '@mui/lab'
import { FormProvider, useForm } from 'react-hook-form'
import { AutoFeedbackRequestForm } from './auto/AutoFeedbackRequestForm'
import { TemplateFeedbackRequestForm } from './template/TemplateFeedbackRequestForm'
import { CustomFeedbackRequestForm } from './custom/CustomFeedbackRequestForm'
import { useOrganization } from '@domains/organization/useOrganization'

interface Props {
	open: boolean
	setOpen: Dispatch<SetStateAction<boolean>>
	trackType: TrackType
	userIntegrationsIds: string[]
	count: number
	integrationCategory: IntegrationCategory
}

interface FormInputs {
	campaignId: string
	feedbackRequestTemplateId: string[]
	message: string
	skillIds: string[]
	dueDate: Date
}

export const MassFeedbackModal = ({
	open,
	setOpen,
	trackType,
	userIntegrationsIds,
	count,
	integrationCategory
}: Props): ReactElement => {
	const { t } = useTranslation()
	const [sendFeedbackRequestMutation, { loading: isLoading }] = useSendBrandFeedbackRequestMutation()
	const { filters } = useFilters()
	const [mode, setMode] = useState(FeedbackRequestMode.Auto)
	const { organization, orgaSkillsData } = useOrganization()
	const message =
		(organization?.defaultOrgaRequest !== '' ? organization?.defaultOrgaRequest : t('modal:massFeedback.message')) ?? ''
	const skillIds = orgaSkillsData.map(({ id }) => id)
	const defaultValues = {
		campaignId: '',
		feedbackRequestTemplateId: [],
		message,
		skillIds,
		dueDate: undefined
	}

	const formMethods = useForm<FormInputs>({ mode: 'onSubmit', defaultValues })

	const { handleSubmit } = formMethods

	const sendFeedbackRequest = async (data: FormInputs): Promise<void> => {
		const variables = {
			userIntegrationsIds,
			trackType,
			filters,
			skillToEvaluateIds: data.skillIds ?? [],
			message: data.message ?? '',
			mode,
			integrationCategory,
			searchQuery: searchQuery(),
			dueDate: data.dueDate,
			feedbackRequestTemplateId: data.feedbackRequestTemplateId?.[0] ?? '',
			campaignId: data.campaignId
		}
		await sendFeedbackRequestMutation({ variables })
		setOpen(false)
	}

	const handleMode = (_e: unknown, mode: FeedbackRequestMode): void => {
		if (mode !== null) setMode(mode)
	}

	const feedbackRequestFormByMode = {
		[FeedbackRequestMode.Auto]: <AutoFeedbackRequestForm />,
		[FeedbackRequestMode.Template]: <TemplateFeedbackRequestForm />,
		[FeedbackRequestMode.Custom]: <CustomFeedbackRequestForm />
	}

	return (
		<Dialog
			open={open}
			onClose={() => {
				setOpen(false)
			}}
		>
			<FormProvider {...formMethods}>
				<DialogTitle>
					<Stack direction="row" justifyContent="space-between">
						{t('modal:massFeedback.title', { count })}
						<ToggleButtonGroup
							color="primary"
							value={mode}
							exclusive
							onChange={handleMode}
							aria-label="Mode"
							sx={{ alignSelf: 'center' }}
							size="small"
						>
							<ToggleButton value={FeedbackRequestMode.Auto}>{t('modal:massFeedback.mode.auto.button')}</ToggleButton>
							<ToggleButton value={FeedbackRequestMode.Template}>
								{t('modal:massFeedback.mode.template.button')}
							</ToggleButton>
							<ToggleButton value={FeedbackRequestMode.Custom}>
								{t('modal:massFeedback.mode.custom.button')}
							</ToggleButton>
						</ToggleButtonGroup>
					</Stack>
				</DialogTitle>
				<Divider />
				<DialogSection>
					<Stack gap={3}>
						{feedbackRequestFormByMode[mode]}
						<LoadingButton
							loading={isLoading}
							onClick={handleSubmit(sendFeedbackRequest)}
							sx={{ alignSelf: 'center' }}
							variant="contained"
						>
							{t('modal:massFeedback.send')}
						</LoadingButton>
					</Stack>
				</DialogSection>
			</FormProvider>
		</Dialog>
	)
}
