import { Route, Switch, useLocation, useRouteMatch } from 'react-router-dom'
import { LocalLoader } from '@shared/components/layout/LocalLoader'
import { TabBar } from '../../components/TabBar'
import { Discussion } from '@domains/discussions/tab/Discussion'
import { IntegrationProgression } from './IntegrationProgression'
import { IntegrationType, TrackType, useUpdateUserPositionMutation } from '@gqlTypes'
import { Container } from '@mui/material'
import { Profile } from '@domains/profile/Profile'
import { INTEGRATION_TYPE } from '@domains/INTEGRATION_TYPE'
import { ImageReturnTab } from '@domains/feedbacks/tab/ImageReturnTab'
import { CareerMatchings } from '@domains/careerMatching/CareerMatchings'
import { Informations } from '@features/details/components/informationsTab/Informations'
import { type ReactElement } from 'react'
import { type useDetailsReturns } from '@features/details/hooks/useDetails'
import { OrganizationFeedbackResponses } from '@domains/brandFeedbacks/brandFeedbackResponses/OrganizationFeedbackResponses'

interface Props {
	infos: useDetailsReturns
	userPositionId: string
	backToolTip: string
	rootPath: string
}

export const UserPositionsDetails = ({ infos, userPositionId, backToolTip, rootPath }: Props): ReactElement => {
	if (infos.isLoading) return <LocalLoader />
	const { state } = useLocation<{ interviewId: string }>()
	const [updateUserPosition] = useUpdateUserPositionMutation()
	const { path } = useRouteMatch()
	const updateIntegration = async (field: string, newValue: string): Promise<void> => {
		if (infos.integrationInfo == null) return
		await updateUserPosition({
			variables: { userPositionId: infos.integrationInfo.id, userPositionInput: { [field]: newValue } }
		})
	}

	const calculatedBackRoute =
		(state?.interviewId ?? '') !== '' ? `/interview/position/${userPositionId}/${state?.interviewId}` : rootPath

	const discussionPath = `${path}/discussion`

	return (
		<Container maxWidth="xl">
			<TabBar
				subMenuTabs={infos.subMenuTabs}
				fullName={infos.integrationInfo?.userInfo?.firstName + ' ' + infos.integrationInfo?.userInfo?.lastName}
				backRoute={calculatedBackRoute}
				backToolTip={backToolTip}
			/>
			<Switch>
				<Route path={`${rootPath}/${userPositionId}`}>
					<Route path={`${path}/profile`}>
						{infos.integrationInfo != null && infos.userInfo != null && (
							<Profile
								integrationType={INTEGRATION_TYPE.POSITION}
								id={userPositionId}
								trackType={TrackType.Position}
								discussionRoute={discussionPath}
							/>
						)}
					</Route>
					<Route path={discussionPath}>
						<Discussion infos={infos} />
					</Route>
					<Route path={`${path}/progression`}>
						<IntegrationProgression
							userIntegrationId={infos.integrationInfo?.id ?? ''}
							userName={infos.integrationInfo?.userInfo?.displayName ?? ''}
							trackType={TrackType.Position}
						/>
					</Route>
					<Route path={`${path}/returns`}>
						{infos.integrationInfo != null && (
							<ImageReturnTab userIntegrationId={userPositionId} userIntegrationType={IntegrationType.Position} />
						)}
					</Route>
					<Route path={`${path}/orgareturn`}>
						<OrganizationFeedbackResponses userId={infos.userInfo?.userId ?? ''} />
					</Route>
					<Route path={`${path}/career`}>
						<CareerMatchings userIntegrationId={infos.integrationInfo?.id ?? ''} trackType={TrackType.Position} />
					</Route>
					<Route exact path={[`${path}/information`, `${path}/`]}>
						{infos.integrationInfo != null && infos.userInfo != null && (
							<Informations infos={infos} updateIntegration={updateIntegration} />
						)}
					</Route>
				</Route>
			</Switch>
		</Container>
	)
}
