import { type ReactNode, type ReactElement } from 'react'
import { LocalLoader } from '@shared/components/layout/LocalLoader'
import { ButtonMore } from '@ui/buttons/ButtonMore'
import { useDispatch, useSelector } from 'react-redux'
import { setSelectedRows } from '@shared/store/actions/selectedRows.actions'
import { Box, Grid, Stack, Typography } from '@mui/material'
import { SelectAllRowButton } from './SelectAllRowButton'
import { type ApolloQueryResult } from '@apollo/client'
import BackButton from '@ui/buttons/BackButton'
import { Search } from './Search'
import { t } from 'i18next'

interface Props<T> {
	title: string
	isLoading: boolean
	dataList: T[]
	setIsAllRowsSelected?: (isAllRowsSelected: boolean) => void
	isAllRowsSelected?: boolean
	fetchMore?: (variables: { variables: { cursor: string } }) => Promise<ApolloQueryResult<unknown>>
	cursor?: string
	hasNextPage?: boolean
	renderItem: (data: T, isSelected: boolean, selectRow: () => void) => ReactElement
	toolbar?: ReactElement
	emptyComponent?: ReactElement
	backRoute?: string
	refetch?: (variables: { searchQuery: string }) => Promise<ApolloQueryResult<unknown>>
	hasSearch?: boolean
	headerLeftComponent?: ReactNode
	searchPlaceHolder?: string
}

export const CardList = <T,>({
	isAllRowsSelected = false,
	setIsAllRowsSelected,
	renderItem,
	dataList,
	isLoading,
	fetchMore,
	cursor = '',
	hasNextPage = false,
	title,
	toolbar,
	emptyComponent,
	backRoute,
	refetch,
	hasSearch = false,
	headerLeftComponent = null,
	searchPlaceHolder = t('searchBar:placeHolder.default')
}: Props<T>): ReactElement => {
	const selectedRows = useSelector(({ selectedRows }: { selectedRows: number[] }) => selectedRows)
	const dispatch = useDispatch()
	const selectRow = (index: number): void => {
		if (setIsAllRowsSelected == null) return
		let newSelectedRows = []
		if (isAllRowsSelected) {
			newSelectedRows = new Array(dataList.length)
				.fill(0)
				.map((_, i) => i)
				.filter((row) => row !== index)
			setIsAllRowsSelected(false)
		} else {
			newSelectedRows = selectedRows?.includes(index)
				? selectedRows.filter((row) => row !== index)
				: [...selectedRows, index]
			if (newSelectedRows.length === dataList.length) setIsAllRowsSelected(true)
		}
		dispatch(setSelectedRows(newSelectedRows))
	}

	if (isLoading) {
		return (
			<Box mt="3" sx={{ height: 300 }}>
				<LocalLoader />
			</Box>
		)
	}

	return (
		<Stack gap={3} sx={{ width: { xs: 400, sm: 500, md: 574, lg: 872, xl: 1160, margin: 'auto' } }}>
			<Stack direction="row" gap={1} alignItems="center" justifyContent="space-between" mt={2}>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={12} lg={4}>
						<Stack direction="row" gap={1}>
							{backRoute != null ? <BackButton route={backRoute} /> : null}
							<Typography variant="h5" color="primary">
								<b>{title}</b>
							</Typography>
							{setIsAllRowsSelected != null && dataList.length !== 0 ? (
								<SelectAllRowButton setIsAllRowsSelected={setIsAllRowsSelected} isAllRowsSelected={isAllRowsSelected} />
							) : null}
							{headerLeftComponent}
						</Stack>
					</Grid>
					<Grid item xs={12} sm={6} lg={4}>
						{hasSearch && refetch != null ? <Search searchPlaceHolder={searchPlaceHolder} refetch={refetch} /> : null}
					</Grid>
					<Grid item container xs={12} sm={6} lg={4} justifyContent="end">
						{toolbar}
					</Grid>
				</Grid>
			</Stack>
			{dataList.length === 0 ? emptyComponent : null}
			<Stack direction="row" flexWrap="wrap" sx={{ gap: 3 }}>
				{dataList.map((data, index) => {
					const isSelected = isAllRowsSelected || selectedRows?.includes(index)
					return renderItem(data, isSelected, () => {
						selectRow(index)
					})
				})}
			</Stack>
			<ButtonMore
				hasNextPage={hasNextPage}
				isLoading={isLoading}
				onClick={() => {
					if (fetchMore == null) return

					void (async () => {
						await fetchMore({ variables: { cursor } })
					})()
				}}
			/>
		</Stack>
	)
}
