import { DefaultLayout } from '@ui/layout/DefaultLayout'
import { type ReactElement } from 'react'
import { MatchingProfileResume } from './MatchingProfileResume'
import { useParams } from 'react-router-dom'
import { type JobMatchingStatusId, useGetPublicResumeQuery, useMeQuery } from '@gqlTypes'
import { JobMatchingStatus } from '../JobMatchingStatus'
import { LocalLoader } from '@shared/components/layout/LocalLoader'
import { JobMatchingBrowserButtons } from './actions/JobMatchingBrowserButtons'
import { ChatButton } from '@domains/discussions/chat/ChatButton'
import { JobMatchingInterview } from './actions/JobMatchingInterview'

export const JobMatchingBrowser = (): ReactElement | null => {
	const { userId, jobMatchingId, trackOfferId, statusId } = useParams<{
		userId: string
		jobMatchingId: string
		trackOfferId: string
		statusId: string
	}>()
	const {
		data,
		error,
		loading: isLoading,
		refetch
	} = useGetPublicResumeQuery({
		variables: { trackOfferId, statusId: statusId === 'all' ? '' : statusId }
	})

	const { data: meData, loading: isMeLoading } = useMeQuery()

	const profiles = data?.getUserOfferJobMatching ?? []
	const profile = profiles?.find(({ jobMatching }) => jobMatching?.id === jobMatchingId)

	if (isLoading || isMeLoading) return <LocalLoader />
	if (error != null || profile?.jobMatching === undefined || meData == null) return null

	const jobMatching = profile?.jobMatching
	const userOffer = profile?.userOffer
	const jobMatchingStatus = jobMatching?.status
	const offername = userOffer?.orgaOffer?.label ?? ''
	const firstname = userOffer?.userInfo?.firstName ?? ''
	const statusHistoryIds = jobMatching?.statusHistoryIds ?? []

	return (
		<DefaultLayout
			isSticky
			backRoute={`/offers/${trackOfferId}/matchings`}
			left={<JobMatchingStatus {...{ jobMatchingId, statusHistoryIds, trackOfferId, ...jobMatchingStatus }} />}
			middle={
				<JobMatchingBrowserButtons
					{...{
						jobMatchingId,
						profiles,
						trackOfferId,
						browserStatusId: statusId,
						jobMatchingStatusId: jobMatchingStatus.id as JobMatchingStatusId,
						offername,
						firstname
					}}
				/>
			}
			right={<JobMatchingInterview jobMatching={jobMatching} refetch={refetch} />}
		>
			<ChatButton userId={userId} displayName={userOffer?.userInfo?.displayName ?? ''} />
			<MatchingProfileResume userId={userId} jobMatchingId={jobMatchingId} />
			<MatchingProfileResume userId={userId} jobMatchingId={jobMatchingId} />
		</DefaultLayout>
	)
}
