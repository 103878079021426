import { type QuestionWithAnswer } from './QuestionWithAnswer'
import { type ReactElement } from 'react'
import { Grid } from '@mui/material'
import { TypographyWithLink } from '@ui/typography/TypographyWithLink'
import { InterviewQuestionType } from '@gqlTypes'
import { InterviewTextQuestion } from './InterviewTextQuestion'
import { InterviewUniqueSelectQuestion } from './InterviewUniqueSelectQuestion'

interface Props extends QuestionWithAnswer {
	isEditable: boolean
}

export const Question = ({ id, label, answer, isEditable, type, responses }: Props): ReactElement => {
	const interviewQuestion = {
		[InterviewQuestionType.UniqueResponse]: (
			<InterviewUniqueSelectQuestion id={id} isEditable={isEditable} answer={answer} responses={responses} />
		),
		[InterviewQuestionType.Text]: <InterviewTextQuestion id={id} isEditable={isEditable} answer={answer} />
	}

	return (
		<Grid container>
			<Grid item xs={4}>
				<TypographyWithLink text={label} sx={{ color: 'lightBlue.main', fontWeight: 'bold' }} />
			</Grid>
			<Grid item xs={8}>
				{interviewQuestion[type]}
			</Grid>
		</Grid>
	)
}
