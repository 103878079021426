import { history } from '../store/MyStoreProvider'
import { generateRoute, ROUTE_HOME } from '../../router/routes'
import useRoleContext from './useRoleContext'
import { menu } from '@domains/mainToolbar/useMainToolbarMenu'

export default function useCheckRoleSecurity(page) {
	const roleContext = useRoleContext()
	if (!roleContext.screens[page].hasReadAccess) {
		const { url } = menu.find((menuItem) => menuItem.id === roleContext.menu[0])
		history.replace(generateRoute(url || ROUTE_HOME))
	}
}
