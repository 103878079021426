import { type ReactElement } from 'react'
import { Stack, Typography, Button } from '@mui/material'
import { OffersPath } from '../../OffersRouter'
import { Link, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { generateRoute } from '../../../../router/routes'

export const EmptyJobMatchingList = (): ReactElement => {
	const { t } = useTranslation()
	const { trackOfferId: offerId } = useParams<{ trackOfferId: string; statusId: string }>()

	return (
		<Stack gap={3} alignItems="center" mt={5}>
			<Typography textAlign={'center'}>{t('offers:jobMatching.list.empty')}</Typography>
			<img src="/assets/img/offers.svg" />
			<Button component={Link} to={generateRoute(OffersPath.update, { offerId })} color="secondary" variant="contained">
				{t('offers:jobMatching.list.updateOffer')}
			</Button>
		</Stack>
	)
}
