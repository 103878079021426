import { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { ChipSelector } from '@ui/form/ChipSelector'
import { useWatch } from 'react-hook-form'
import { useGetMacroSoftSKillsQuery } from '@gqlTypes'
import { Box } from '@mui/material'
import { GraphRadar } from '@shared/components/GraphRadar'
import { pick } from '#helpers/object.helper'
import { ColumnLayout } from '@shared/components/layout/ColumnLayout'

export const TopSoftSkillSelector = (): ReactElement | null => {
	const { t } = useTranslation()
	const { data, loading: isLoading } = useGetMacroSoftSKillsQuery()

	const softSkills = data?.getMacroSoftSKills ?? []
	const selectedScore = useWatch<string>({ name: 'topSoftSkillIds' }) ?? ''
	const selectedScoreValues = [9, 8, 7]
	const scoreToSplit =
		softSkills.length * 5.5 - selectedScoreValues.slice(0, selectedScore.length).reduce((p, c) => p + c, 0)

	const defaultScore = scoreToSplit / (softSkills.length - selectedScore.length)

	const dimensions = softSkills.map((skill, index) => {
		const selectedScoreIndex = selectedScore.indexOf(skill.id)
		const score = selectedScoreIndex >= 0 ? selectedScoreValues[selectedScoreIndex] : defaultScore
		return { ...pick(skill, 'id', 'label', 'color', 'icon'), selfScore: 0, feedbackScore: score, index }
	})
	return (
		<ColumnLayout title={t('offers:details.expectedSkills.softSkills.title')}>
			<ChipSelector
				name="topSoftSkillIds"
				items={softSkills}
				isLoading={isLoading}
				maxSelectableItem={3}
				withOrder
				minSelectableItem={3}
				isRequired={false}
				errorMessage={t('offers:details.expectedSkills.softSkills.error', { count: 3 })}
			/>
			<Box sx={{ textAlign: 'center' }}>
				<GraphRadar dimensions={dimensions} />
			</Box>
		</ColumnLayout>
	)
}
