import { useEffect, useState } from 'react'
import { discussionService } from '../../services/Services'
import { useErrorHandler } from 'react-error-boundary'
import { readQuerySnapshot } from '@infrastructure/firebase/firestore.helper'
import { type DiscussionUserFragment, useDecryptMessagesLazyQuery, useUpdateDiscussionUserMutation } from '@gqlTypes'
import { MESSAGE_TYPE } from './MESSAGE_TYPE'
import { type Timestamp } from '@firebase/firestore-types'

export interface Message {
	senderId: string
	id: string
	creationDate: Timestamp
	type: string
	softSkillId: string
	score: number
	imageReturnResponseId: string
	text: string
}

export interface DecryptedMessage extends Message {
	createdAt: Date
	decrypted: string
	hasTimeStamp: boolean
	isSelf: boolean
	author: string
}

export const useDiscussionContent = (
	discussionUser: DiscussionUserFragment | undefined
): { messages: DecryptedMessage[]; isLoadingMessages: boolean } => {
	const errorHandler = useErrorHandler()

	const [updateDiscussionUser] = useUpdateDiscussionUserMutation()
	const [isLoadingMessages, setIsLoadingMessages] = useState(false)
	const [messages, setMessages] = useState<DecryptedMessage[]>([])
	const [decryptMessages] = useDecryptMessagesLazyQuery()
	const isRead = discussionUser?.read ?? false

	useEffect(() => {
		if (discussionUser == null || isRead) return
		const update = async (): Promise<void> => {
			if (isRead) return
			await updateDiscussionUser({
				variables: { id: discussionUser.id ?? '', discussionUserInput: { read: true } }
			})
		}
		void update()
	}, [discussionUser])

	// get messages socket
	useEffect(() => {
		if (discussionUser == null) return

		setIsLoadingMessages(true)
		let crypted
		const unsubscribe = discussionService
			.getDiscussionMessages(discussionUser.discussionId)
			.onSnapshot(async (messagesSnapshot) => {
				crypted = readQuerySnapshot(messagesSnapshot) as Message[]
				const cryptedMessages = crypted.map((message) => message.text)
				const variables = {
					discussionId: discussionUser.discussionId ?? '',
					messages: cryptedMessages ?? ''
				}
				const { data } = await decryptMessages({ variables })
				const decrypted = crypted
					.map((message, index) => {
						return { ...message, decrypted: data?.decryptMessages[index] ?? '' }
					})
					.sort((msgA, msgB) => msgA.creationDate.seconds - msgB.creationDate.seconds)

				let latest = 0
				const { userRef, recipientRef } = discussionUser
				const builded = decrypted
					.filter((message) => message.type !== MESSAGE_TYPE.SOFTSKILL_RESPONSE)
					.map((message) => {
						let hasTimeStamp = false
						// Check if there is a 12h difference between 2 messages
						if (latest === 0 || Math.abs(latest - message.creationDate.seconds) > 43200) {
							hasTimeStamp = true
							latest = message.creationDate.seconds
						}
						const createdAt = message.creationDate.toDate()
						const isSelf = message.senderId === discussionUser.userId
						const author = isSelf ? (userRef?.displayName ?? '') : (recipientRef?.displayName ?? '')

						return { ...message, createdAt, isSelf, hasTimeStamp, author }
					})
				setMessages(builded)
				setIsLoadingMessages(false)
			})

		return () => {
			if (unsubscribe != null) unsubscribe()
		}
	}, [discussionUser, errorHandler])

	return {
		messages,
		isLoadingMessages
	}
}
