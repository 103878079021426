import AutoCompleteFilter from '@features/lists/components/Filters/AutoCompleteFilter'
import { useGetPermitsQuery } from '@gqlTypes'
import { filterOperation } from '@ui/filter/FilterForm'
import { InputFilter } from '@ui/filter/InputFilter'
import { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

export const PermitFilter = (): ReactElement | null => {
	const { t } = useTranslation()

	const { data, loading: isLoading } = useGetPermitsQuery()

	if (isLoading || data?.getPermits == null) return null
	const educationLevels = data.getPermits.map(({ id: value, label }) => ({ value, label }))

	return (
		<InputFilter keyName="userRef.permit" operation={filterOperation.EQUAL}>
			<AutoCompleteFilter
				options={educationLevels}
				label={t('offers:resumeLibrary.filters.permit.label')}
				width="100%"
			/>
		</InputFilter>
	)
}
