import { Stack } from '@mui/material'
import { type ReactElement } from 'react'
import { ObjectiveStatusSelect } from '../status/ObjectiveStatusSelect'
import { InputTextFieldForm } from '@ui/form/inputTextFieldForm'
import { useOrganization } from '@domains/organization/useOrganization'
import { ObjectiveCompletion } from './ObjectiveCompletion'
import { useCustomTranslation } from '#translations/useCustomTranslation'
interface Props {
	isReadOnly: boolean
}

export const QuantityRightFields = ({ isReadOnly }: Props): ReactElement => {
	const { t } = useCustomTranslation()
	const key = 'interview:objectives.modal'
	const { organization } = useOrganization()
	const isObjectivesShortTerme = organization?.isObjectivesShortTerme ?? false

	return (
		<Stack spacing={2}>
			<Stack direction={'row'} gap={2}>
				<InputTextFieldForm
					sx={{ flex: 1 }}
					disabled={isReadOnly}
					name="quantity.value"
					label={t(`${key}.value`)}
					type="number"
				/>
				<ObjectiveStatusSelect disabled={isReadOnly} />
				<ObjectiveCompletion disabled={isReadOnly} name="quantity.completion" />
			</Stack>
			<InputTextFieldForm disabled={isReadOnly} name="comment" label={t(`${key}.comment`)} multiline rows={3} />
			{isObjectivesShortTerme ? (
				<InputTextFieldForm
					disabled={isReadOnly}
					name="quantity.reevaluatedTarget"
					label={t(`${key}.reevaluatedTarget`)}
					type="number"
				/>
			) : (
				<InputTextFieldForm
					disabled={isReadOnly}
					name="quantity.nextTarget"
					label={t(`${key}.nextTarget`)}
					type="number"
				/>
			)}
		</Stack>
	)
}
