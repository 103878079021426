import { useGetCampaignsQuery } from '@gqlTypes'
import { AutocompleteSelect } from '@ui/form/AutocompleteSelect'
import { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
	isRequired?: boolean
}

export const CampaignSelector = ({ isRequired = false }: Props): ReactElement => {
	const { t } = useTranslation()
	const { data, loading } = useGetCampaignsQuery({ fetchPolicy: 'cache-and-network' })
	const items = data?.getCampaigns.map(({ id, label }) => ({ value: id, label })) ?? []
	return (
		<AutocompleteSelect
			label={t('modal:massFeedback.campaign.label')}
			name="campaignId"
			items={items}
			createOptionLabel={t('modal:massFeedback.campaign.add')}
			loading={loading}
			rules={isRequired ? { required: t('form:errors.required') } : undefined}
		/>
	)
}
