import { type ApolloQueryResult } from '@apollo/client'
import { Filters } from '@features/lists/components/Filters'
import { FiltersContent } from '@features/lists/components/FiltersContent'
import { LeftBar } from '@features/lists/components/LeftBar'
import { type ReactElement } from 'react'
import { OfferTypeFilter } from './OfferTypeFilter'
import { ContractDurationFilter } from './ContractDurationFilter'
import { LanguageFilter } from './LanguageFilter'
import { SoftskillFilter } from './SoftskillFilter'
import { UserFunctionAndSkillFilter } from './UserFunctionAndSkillFilter'
import { WorkArrangementFilter } from './WorkArrangementFilter'
import { MonthDateFilter } from '@features/lists/components/Filters/MonthDateFilter'
import { useTranslation } from 'react-i18next'
import { PermitFilter } from './PermitFilter'
import { NationalitiesFilter } from './NationalitiesFilter'

interface Props {
	refetch: () => Promise<ApolloQueryResult<unknown>>
}

export const ResumeLibraryFilterBar = ({ refetch }: Props): ReactElement => {
	const { t } = useTranslation()
	return (
		<LeftBar>
			<Filters refetch={refetch}>
				<FiltersContent>
					<OfferTypeFilter />
					<ContractDurationFilter />
					<WorkArrangementFilter />
					<UserFunctionAndSkillFilter />
					<LanguageFilter />
					<SoftskillFilter />
					<MonthDateFilter keyName="startMonthDate" label={t('offers:resumeLibrary.filters.StartMonthDate.label')} />
					<NationalitiesFilter />
					<PermitFilter />
				</FiltersContent>
			</Filters>
		</LeftBar>
	)
}
