import { Link } from 'react-router-dom'
import { type ReactNode, type ReactElement } from 'react'
import Button, { type ButtonProps } from '@mui/material/Button'

interface Props extends ButtonProps {
	route: string
	label?: string
	state?: Record<string, unknown>
	children?: ReactNode
}

export const RouterLink = ({ route, label, state = {}, children, ...props }: Props): ReactElement => {
	return (
		<Button component={Link} to={{ pathname: route, state }} fullWidth variant="text" {...props}>
			{label}
			{children}
		</Button>
	)
}
