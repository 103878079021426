import { type ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'
import AddIcon from '@mui/icons-material/Add'
import { IconAction } from '@ui/icon/IconAction'
import { NewInterviewDrawer } from './NewInterviewDrawer'

interface Props {
	reset: () => void
}

export const NewInterview: React.FC<Props> = ({ reset }: Props): ReactElement | null => {
	const { t } = useTranslation()
	const [isOpen, setIsOpen] = useState(false)

	return (
		<>
			<IconAction
				tooltip={t('drawer:interview.title')}
				onClick={() => {
					setIsOpen(true)
				}}
			>
				<AddIcon />
			</IconAction>
			<NewInterviewDrawer isOpen={isOpen} setIsOpen={setIsOpen} reset={reset} />
		</>
	)
}
