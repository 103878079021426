import { Stack, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { type ReactElement } from 'react'
import { ConversationFrame } from '@domains/discussions/conversation/ConversationFrame'
import { DiscussionMessages } from '@domains/discussions/DiscussionMessages'
import { useDiscussionContent } from '@domains/discussions/useDiscussionContent'
import { useGetDiscussionUserByUserFromOrganizationQuery, usePostMessageFromOrganizationMutation } from '@gqlTypes'
import { UserAvatar } from '@ui/UserAvatar'

interface Props {
	userId: string
	displayName: string
	avatarUrl?: string
}

export const ChatContent = ({ userId, displayName, avatarUrl }: Props): ReactElement | null => {
	const { data, refetch } = useGetDiscussionUserByUserFromOrganizationQuery({ variables: { userId } })

	const discussionUser = data?.getDiscussionUserByUserFromOrganization ?? undefined

	const { messages, isLoadingMessages } = useDiscussionContent(discussionUser)
	const [postMessage, { loading: isSendingMessage }] = usePostMessageFromOrganizationMutation()
	const handleNewMessage = async (messageText: string): Promise<void> => {
		await postMessage({ variables: { recipientId: userId, messageText } })
		if (discussionUser == null) await refetch()
	}

	return (
		<Box sx={{ width: '400px' }}>
			<Box sx={{ backgroundColor: 'primary.main', padding: '20px' }}>
				<Stack direction="row" spacing={2}>
					<UserAvatar user={{ avatarUrl }} />
					<Typography variant="h6" color={'white'}>
						{displayName}
					</Typography>
				</Stack>
			</Box>
			<ConversationFrame loading={isSendingMessage} onNewMessage={handleNewMessage} sx={{ height: '50vh' }}>
				<DiscussionMessages displayName={displayName} messages={messages} isLoading={isLoadingMessages} />
			</ConversationFrame>
		</Box>
	)
}
