import { type ReactElement, useState } from 'react'
import { CandidatesFilterBar } from './CandidatesFilterBar'
import { useDocumentTitle } from '@shared/hooks/useDocumentTitle'
import { useTranslation } from 'react-i18next'
import useCheckRoleSecurity from '@shared/hooks/useRoleSecurity'
import { ScreenId, useGetUserOfferListQuery } from '@gqlTypes'
import { useFilters } from '@shared/hooks/useFilters'
import { Container, Typography } from '@mui/material'
import { CardList } from '@features/lists/components/CardList'
import { CandidateCard } from './CandidateCard'
import { useDefaultFilter } from '@shared/hooks/useDefaultFilter'
import { useOrganization } from '@domains/organization/useOrganization'
import { searchQuery } from '@features/lists/components/Search'

export const Candidates = (): ReactElement => {
	const { t } = useTranslation(['lists', 'candidates'])
	useDocumentTitle(t('candidates:title'))
	useCheckRoleSecurity(ScreenId.Candidates)
	const { filters } = useFilters()
	const { organizationId } = useOrganization()
	const [isAllRowsSelected, setIsAllRowsSelected] = useState(false)

	const variables = { organizationId, limit: 12, filters, searchQuery: searchQuery() }
	const { loading, data, refetch, fetchMore } = useGetUserOfferListQuery({
		variables
	})
	useDefaultFilter({ refetch })

	let { nodes = [], cursor, hasNextPage, count = 0 } = data?.getUserOfferList ?? {}
	cursor = cursor ?? ''
	hasNextPage = hasNextPage ?? false

	return (
		<Container maxWidth="xl">
			<CardList
				renderItem={(item, isSelected, selectRow) => (
					<CandidateCard key={item.id} offer={item} isSelected={isSelected} selectRow={selectRow} />
				)}
				isAllRowsSelected={isAllRowsSelected}
				setIsAllRowsSelected={setIsAllRowsSelected}
				dataList={nodes}
				isLoading={loading}
				fetchMore={fetchMore}
				cursor={cursor}
				hasNextPage={hasNextPage}
				title={t('candidates:title', { count })}
				refetch={refetch}
				hasSearch
				searchPlaceHolder={t('searchBar:placeHolder.candidates')}
				emptyComponent={<Typography sx={{ fontSize: 24 }}>{t('candidates:dataGrid.empty')}</Typography>}
				toolbar={
					<CandidatesFilterBar isAllRowsSelected={isAllRowsSelected} refetch={refetch} dataList={nodes} count={count} />
				}
			/>
		</Container>
	)
}
