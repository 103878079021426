import { useMeQuery, type OrganizationListItem } from '@gqlTypes'
import { firebaseAuth } from '@infrastructure/firebase/firebase.helper'
import { Avatar, Stack, Typography } from '@mui/material'
import { teal } from '@mui/material/colors'
import { type ReactElement } from 'react'

interface Props {
	organizationList: OrganizationListItem[]
}

export const UserAccount = ({ organizationList }: Props): ReactElement => {
	const currentUser = firebaseAuth().currentUser
	const { data } = useMeQuery()
	const hasMultipleOrganizations = organizationList.length > 1
	return (
		<>
			<Stack sx={{ alignItems: 'center', p: 2 }} spacing={1}>
				<Avatar sx={{ backgroundColor: teal[200] }} src={data?.me?.photoURL}>
					{currentUser?.displayName != null ? currentUser.displayName.substring(0, 1).toUpperCase() : 'U'}
				</Avatar>
				<Typography sx={{ fontWeight: 'bold' }}>{currentUser?.displayName ?? ''}</Typography>
				{!hasMultipleOrganizations ? <Typography>{organizationList[0]?.organizationName ?? ''}</Typography> : null}
				<Typography>{currentUser?.email ?? ''}</Typography>
			</Stack>
		</>
	)
}
