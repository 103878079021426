import { type ReactElement, useState } from 'react'
import { Box, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material'
import { type OrganizationListItem, useSwitchOrganizationMutation } from '@gqlTypes'
import { useApolloClient } from '@apollo/client'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { resetFilters } from '@shared/store/actions/filters.actions'
import { useAuth } from '@features/login/useAuth'
import { ErrorHandler } from '#helpers/error.helper'
import { useCustomTranslation } from '#translations/useCustomTranslation'

interface Props {
	organizationList: OrganizationListItem[]
	currentOrganizationId: string
	handleClose: () => void
}

export const OrganizationSwitcher = ({ organizationList, currentOrganizationId, handleClose }: Props): ReactElement => {
	const { t } = useCustomTranslation()
	const [switchOrganization] = useSwitchOrganizationMutation()

	const [value, setValue] = useState(currentOrganizationId)
	const client = useApolloClient()
	const dispatch = useDispatch()
	const history = useHistory()
	const { me } = useAuth()

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		;(async () => {
			const selectedOrganizationId = (event.target as HTMLInputElement).value
			setValue(selectedOrganizationId)
			await switchOrganization({ variables: { organizationId: selectedOrganizationId } })
			dispatch(resetFilters())
			handleClose()
			history.push('/')
			await me()
			await client.resetStore()
		})().catch(ErrorHandler)
	}

	return (
		<Box>
			<FormControl sx={{ m: 2 }}>
				<FormLabel sx={{ mb: 1, color: 'primary.main' }} id="buttons-group-label">
					{t('menu.user.organization.list')}
				</FormLabel>
				<RadioGroup
					aria-labelledby="buttons-group-label"
					name="buttons-group"
					value={value}
					onChange={handleChange}
					sx={{ ml: 1 }}
				>
					{organizationList.map(({ organizationId, organizationName }) => (
						<FormControlLabel
							key={organizationId}
							value={organizationId}
							control={<Radio />}
							label={organizationName}
						/>
					))}
				</RadioGroup>
			</FormControl>
		</Box>
	)
}
