import { createTheme } from '@mui/material'

declare module '@mui/material/Chip' {
	interface ChipPropsVariantOverrides {
		twoTone: true
	}
}

const theme = createTheme({
	palette: {
		primary: {
			light: '#205186',
			main: '#0A2E56',
			dark: '#07203C',
			contrastText: '#FFF'
		},
		secondary: {
			light: '#1FFB91',
			main: '#1ED37B',
			dark: '#00ef5c',
			contrastText: '#FFF'
		},
		success: {
			main: '#1ED37B'
		},
		warning: {
			dark: '#a96b33',
			main: '#FB881F',
			light: '#FBBB1F'
		},
		darkBlue: {
			main: '#05182D',
			light: '#0A2E56',
			contrastText: '#FFF'
		},
		lightBlue: {
			main: '#0A2E56',
			light: '#4FC0FF',
			contrastText: '#FFF'
		},
		progress: {
			light: '#1FFB91',
			main: '#1ED37B',
			dark: '#1ED37B'
		},
		star: {
			main: '#FBBB1F'
		},
		included: {
			main: '#1ED37B'
		},
		excluded: {
			main: '#FB881F'
		},
		grays: {
			gray1: '#EEEEEE',
			gray2: '#BDBDBD',
			gray3: '#757575',
			gray4: '#4F4F4F',
			gray5: '#333333'
		},
		text: {
			primary: '#4F4F4F'
		},
		messageSelf: {
			main: '#E0EDFF'
		},
		messageOther: {
			main: '#F5F9FF'
		},
		alphaWhite: {
			46: '#FFFFFF75'
		},
		backgroundBlue: {
			main: '#E7F1FF'
		},
		greenCount: { main: '#1ED37B', secondary: '#1ED37B33' }
	},
	typography: {
		fontFamily: ['Lato', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(','),
		button: {
			textTransform: 'none',
			fontWeight: 600
		},
		h2: {
			fontSize: '20px',
			fontWeight: 'bold',
			color: '#0A2E56'
		}
	},
	components: {
		MuiChip: {
			variants: [
				{
					props: { variant: 'twoTone' },
					style: {
						fontWeight: 'bold',
						backgroundColor: '#1ED37B33',
						color: '#1ED37B',
						borderRadius: '7px'
					}
				},
				{
					props: { variant: 'twoTone', color: 'warning' },
					style: {
						backgroundColor: '#FB881F33',
						color: '#FB881F'
					}
				}
			]
		},
		MuiTooltip: {
			styleOverrides: {
				tooltip: {
					backgroundColor: '#0A2E56',
					borderRadius: '7px'
				}
			}
		},
		MuiTableCell: {
			styleOverrides: {
				root: {
					borderBottomStyle: 'dashed'
				}
			}
		},
		MuiCard: {
			styleOverrides: {
				root: {
					borderRadius: '7px'
				}
			}
		},
		MuiDialogActions: {
			styleOverrides: {
				root: {
					padding: '20px 24px'
				}
			}
		}
	},
	overrides: {
		MuiDialogTitle: {
			root: {
				textAlign: 'center',
				color: '#05182D',
				paddingTop: 26,
				paddingBottom: 26,
				'& .MuiTypography-h6': {
					fontWeight: '700'
				}
			}
		},
		MuiInputLabel: {
			root: {
				fontStyle: 'italic'
			}
		},
		MuiCardHeader: {
			title: {
				textAlign: 'center',
				fontSize: 20,
				fontWeight: 700,
				color: '#0A2E56'
			}
		}
	}
})

export default theme
